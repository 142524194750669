<!-- Modal CROP -->
<div class="modal fade modal-crop" [id]="cropId" tabindex="-1" role="dialog" aria-labelledby="moreLabel" aria-hidden="true"
     data-backdrop="static">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <div class="d-flex align-items-center">
                    <svg-icon class="svg-icon- svg-primary"  src="assets/svg/addimage.svg"></svg-icon>
                    <h3 class="modal-title ml-3">
                        <strong class="text-primary">Enviar Imagem</strong>
                    </h3>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg-icon class="svg-icon-primary" src="assets/svg/close.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body px-0 pb-0 d-flex flex-column">
                <div class="d-flex ml-3 align-items-center" *ngIf="!isNullOrUndefined(imageChangedEvent)">
                    <div class="ml-3 d-flex align-items-center is-cursor" (click)="onReset()">
                        <svg-icon class="svg-icon svg-primary mr-2" src="assets/svg/addimage.svg"></svg-icon>
                    </div>
                    <div class="ml-3 d-flex align-items-center is-cursor" (click)="onImageRotate()">
                        <svg-icon  class="svg-icon rotate-svg svg-primary icon-modal" src="assets/svg/rotate.svg"></svg-icon>
                    </div>
                    <div class="ml-3 d-flex align-items-center is-cursor" (click)="onImageSave()">
                        <svg-icon  class="svg-icon rotate-svg svg-primary ml-1 icon-modal" src="assets/svg/check2.svg"></svg-icon>
                    </div>
                </div>
                <div class="px-4">
                    <div class="px-4">
                        <div class="d-flex justify-content-center align-items-center flex-column">
                            <div class="description__photo" *ngIf="isNullOrUndefined(imageChangedEvent)">
                                <div class="border-0 w-100">
                                    <div class="file-field text-center mb-4">
                                        <img src="assets/images/prevent-upload.png" alt="Upload" class="img-fluid"/>
                                    </div>
                                </div>
                                <input class="m-3" type="file" class="description__photo--file" name="profile_photo" onclick="this.value = null"
                                       (change)="fileChangeEvent($event)" #newItem/>
                            </div>
                            <image-cropper
                                    [format]="'jpeg'"
                                    [imageQuality]="100"
                                    [imageChangedEvent]="imageChangedEvent"
                                    [maintainAspectRatio]="true"
                                    [aspectRatio]="cropAspectRatio"
                                    (imageCropped)="imageCropped($event)"
                                    (imageLoaded)="imageLoaded()"
                                    (cropperReady)="cropperReady()"
                                    (loadImageFailed)="loadImageFailed()"
                                    [canvasRotation]="cropCanvasRotation"
                                    [resizeToWidth]="cropResizeToWidth"
                                    [resizeToHeight]="cropResizeToHeight"
                            ></image-cropper>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<!--

1410
200-->
