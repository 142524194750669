<div class="container measures">
    <div class="row">
        <!-- Card de Perguntas-->
        <div class="col-xl-8 col-xxl-8">
            <div class="scroll_primary pt-3 pr-3">
                <div class="config_top">
                    <div class="d-flex">
                        <svg-icon routerLink="/participants" class="is-cursor mr-3"
                                  src="assets/svg/back.svg"></svg-icon>
                        <h2 class="page-header__title large">
                            <strong>Auditoria</strong>
                        </h2>
                    </div>
                    <!--Barra de Recebido-->
                    <div class="received text-capitalize bg-{{standArt.status}}">{{getTranslate(standArt.status)}}</div>
                </div>
                <div class="row justify-content-center mt-4">
                    <div class="col-sm-12 col-lg-9">
                        <div class="register-page_status status-{{standArt.status}}">
                            <!--<span (click)="actionGetStandart('adhesion')">
                            </span>
                            <span (click)="actionGetStandart('standardization')">
                            </span>-->
                            <!--<div class="d-flex justify-content-between align-items-center position-relative"
                                 *ngIf="standArt.type === 'adhesion'">
                                <div class="status_item" (click)="actionGetStandart('adhesion')">
                                    <p class="status_text text-status-{{standArt.status}}">Adesão</p>
                                    <svg-icon class="icon_status"
                                              src="assets/svg/{{standArt.status}}-status.svg"></svg-icon>
                                    <p class="status_text text-status-{{standArt.status}} bottom">{{getTranslate(standArt.status)}}</p>
                                </div>
                                <div class="status_item" (click)="actionGetStandart('standardization')">
                                    <ng-container *ngIf="standArt.status !== 'approved'">
                                        <p class="status_text status_text_secondary">Padronização</p>
                                        <svg-icon class="icon_status2" src="assets/svg/editadesion.svg"></svg-icon>
                                        <p class="status_text bottom">
                                            &nbsp;
                                        </p>
                                    </ng-container>
                                    <ng-container *ngIf="standArt.status === 'approved'">
                                        <p class="status_text text-tertiary">Padronização</p>
                                        <div style="width: 31px;height: 31px;border-radius: 100%;border: solid 3px #00BB7F;background-color: white">
                                            &nbsp;
                                        </div>
                                        <p class="status_text bottom">
                                            &nbsp;
                                        </p>
                                    </ng-container>
                                </div>
                                <div class="status_bar">&nbsp;</div>
                            </div>-->
                            <div class="d-flex justify-content-between align-items-center position-relative"
                                 *ngIf="standArt?.type === 'adhesion'">
                                <div class="status_item" (click)="actionGetStandart('adhesion')">
                                    <p class="status_text text-status-{{standArt?.status}}">Adesão</p>
                                    <svg-icon class="icon_status"
                                              src="assets/svg/{{standArt?.status}}-status.svg"></svg-icon>
                                    <p class="status_text text-capitalize text-status-{{standArt?.status}} bottom">{{getTranslate(standArt?.status)}}</p>
                                </div>
                                <div class="status_item" (click)="actionGetStandart('standardization')">
                                    <p class="status_text text-status-{{standArt?.sons?.status}}">Padronização</p>
                                    <svg-icon class="icon_status2"
                                              src="assets/svg/{{isNullOrUndefined(standArt?.sons?.status) ? 'standardization.svg' : standArt?.sons?.status + '-status.svg'}}"></svg-icon>
                                    <p class="status_text text-capitalize text-status-{{standArt?.sons?.status}} bottom">
                                        {{getTranslate(standArt?.sons?.status)}}
                                    </p>
                                </div>
                                <div class="status_bar status_standardization">&nbsp;</div>
                            </div>
                            <div class="d-flex justify-content-between align-items-center position-relative"
                                 *ngIf="standArt.type === 'standardization'">
                                <div class="status_item" (click)="actionGetStandart('adhesion')">
                                    <p class="status_text text-status-{{standArt?.father?.status}}">Adesão</p>
                                    <svg-icon class="icon_status"
                                              src="assets/svg/{{standArt?.father?.status}}-status.svg"></svg-icon>
                                    <p class="status_text text-capitalize text-status-{{standArt?.father?.status}} bottom">{{getTranslate(standArt?.father?.status)}}</p>
                                </div>
                                <div class="status_item" (click)="actionGetStandart('standardization')">
                                    <p class="status_text text-status-{{standArt?.status}}">Padronização</p>
                                    <svg-icon class="icon_status2"
                                              src="assets/svg/{{standArt?.status}}-status.svg"></svg-icon>
                                    <p class="status_text text-capitalize text-status-{{standArt?.status}} bottom">
                                        {{getTranslate(standArt?.status)}}
                                    </p>
                                </div>
                                <div class="status_bar status_standardization">&nbsp;</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="config_top mt-3">
                    <div class="d-flex">
                        <h2 class="page-header__title large">
                            <strong class="text-capitalize">{{getTranslate(standArt?.type)}}</strong>
                        </h2>
                    </div>
                </div>
                <div class="top_card">
                    <div class="d-flex flex-column flex-grow-1 my-2">
                        <p>CNPJ</p>
                        <b>{{standArt?.createdBy?.cnpj}}</b>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 my-2">
                        <p>Data de Recebimento</p>
                        <b>{{standArt?.createdAt |date:'dd/MM/yyyy'}}</b>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 my-2">
                        <p>Perfil</p>
                        <b>{{standArt?.createdBy?.resaleType === 'Novo Negócio' ? 'Novo Negócio' : 'Debranding'}}</b>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 my-2">
                        <p>Marca</p>
                        <b>{{standArt?.createdBy?.branding}}</b>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 my-2">
                        <p>Responsável pela Padronização</p>
                        <span class="d-flex justify-content-between">
                            <b>{{getResponsibleStandardization(searchResaleResult.responsibleStandardization)}}</b>
                            <a class="btn btn-sm btn-outline-primary"
                               href="javascript:$('#responseFleet').modal('show'); void('');">Alterar</a>
                        </span>
                    </div>
                </div>

                <div class="row" *ngIf="documentsProject.length > 0">
                    <div class="col-12">
                        <div class="card card-primary-dark">
                            <div class="row p-3 pb-0">
                                <div class="col-12">
                                    <h4 class="page-header__title mb-1">
                                        <strong>Projeto</strong>
                                    </h4>
                                </div>
                            </div>
                            <div class="row w-100 px-3 mb-2">
                                <div class="col-12 col-md-6">
                                    <h4 class="page-header__title small mb-4">
                                        Status:<br/> <strong
                                            class="text-capitalize">{{documentsProject[documentsProject.length - 1]?.status | translate}}</strong><br/><br/>
                                        Data do Projeto:<br/>
                                        <strong>{{documentsProject[documentsProject.length - 1]?.createdAt | date: 'dd/MM/yyyy'}}</strong><br/><br/>
                                        <span *ngIf="!isNullOrUndefined(documentsProject) && !isNullOrUndefined(documentsProject[documentsProject.length - 1]?.approvedAt)">Data Aprovação:<br/> <strong>{{documentsProject[documentsProject.length - 1]?.approvedAt | date: 'dd/MM/yyyy'}}</strong><br/></span>
                                    </h4>
                                </div>
                                <div class="col-12 col-md-6 text-center">
                                    <a href="{{documentsProject[documentsProject.length - 1]?.url}}" target="_blank"
                                       class="d-flex align-items-center justify-content-center flex-column">
                                        <svg-icon src="assets/svg/file-double.svg" [stretch]="true"
                                                  [svgStyle]="{'width.px': 40, 'height.px': 50}"></svg-icon>
                                        <span class="text-primary">Visualizar</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row my-4">

                    <div class="col-12 col-md-6">
                        <div class="config_top">
                            <div class="d-flex align-items-center flex-wrap">
                                <h2 class="page-header__title large mb-3 pr-4">
                                    <strong>Sua revenda possui frota?</strong>
                                </h2>
                            </div>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" class="custom-control-input" id="customRadio" name="example"
                                   value="customEx"
                                   [disabled]="true"
                                   [checked]="!searchResaleResult.isFleet">
                            <label class="custom-control-label" for="customRadio">Não</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" class="custom-control-input" id="customRadio2" name="example"
                                   value="customEx"
                                   [disabled]="true"
                                   [checked]="searchResaleResult.isFleet"/>
                            <label class="custom-control-label" for="customRadio2">Sim</label>
                        </div>
                    </div>

                    <div class="col-12 col-md-6">
                        <div class="config_top">
                            <div class="d-flex align-items-center flex-wrap">
                                <h2 class="page-header__title large mb-3 pr-4">
                                    <strong>Revenda foi Pintada?</strong>
                                </h2>
                            </div>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" class="custom-control-input" id="resalePaint" name="resalePaint"
                                   [value]="false"
                                   (change)="setResalePaint(false)"
                                   [checked]="standArt.isPaintedResale === false"/>
                            <label class="custom-control-label" for="resalePaint">Não</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" class="custom-control-input" id="resalePaint2"
                                   name="resalePaint"
                                   [value]="true"
                                   (change)="setResalePaint(true)"
                                   [checked]="standArt.isPaintedResale === true"/>
                            <label class="custom-control-label" for="resalePaint2">Sim</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" class="custom-control-input" id="resalePaint3"
                                   name="resalePaint"
                                   [value]="null"
                                   (change)="setResalePaint(null)"
                                   [checked]="standArt.isPaintedResale === null"/>
                            <label class="custom-control-label" for="resalePaint3">N/A</label>
                        </div>
                    </div>

                </div>

                <div class="row my-4">
                    <div class="col-12">
                        <hr>
                    </div>
                </div>

                <div class="row my-4">
                    <div class="col-12 " [ngClass]="{'col-md-6' : !standArt.isHasTotem, 'col-md-4' : standArt.isHasTotem}">
                        <div class="card p-4">
                            <div class="config_top">
                                <div class="d-flex align-items-center flex-wrap">
                                    <h2 class="page-header__title mb-3 pr-4">
                                        <strong>Revenda possui totem/projeto especial?</strong>
                                    </h2>
                                </div>
                            </div>
                            <div class="d-flex">
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" class="custom-control-input" id="isHasTotemTrue" name="isHasTotem"
                                           [value]="false"
                                           [(ngModel)]="standArt.isHasTotem"
                                           (ngModelChange)="setTotem(false)"/>
                                    <label class="custom-control-label" for="isHasTotemTrue">Não</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" class="custom-control-input" id="isHasTotemFalse" name="isHasTotem"
                                           [value]="true"
                                           [(ngModel)]="standArt.isHasTotem"
                                           (ngModelChange)="setTotem(true)"/>
                                    <label class="custom-control-label" for="isHasTotemFalse">Sim</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4" *ngIf="standArt.isHasTotem">
                        <div class="card p-4">
                            <div class="config_top">
                                <div class="d-flex align-items-center flex-wrap">
                                    <h2 class="page-header__title mb-3 pr-4">
                                        <strong>Totem/projeto especial é luminoso?</strong>
                                    </h2>
                                </div>
                            </div>
                            <div class="d-flex">
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" class="custom-control-input" id="isTotemLuminousmTrue" name="isTotemLuminous"
                                           [value]="false"
                                           [(ngModel)]="standArt.isTotemLuminous"
                                           (ngModelChange)="setTotemLuminous(false)" />
                                    <label class="custom-control-label" for="isTotemLuminousmTrue">Não</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" class="custom-control-input" id="isTotemLuminousFalse" name="isTotemLuminous"
                                           [value]="true"
                                           [(ngModel)]="standArt.isTotemLuminous"
                                           (ngModelChange)="setTotemLuminous(true)" />
                                    <label class="custom-control-label" for="isTotemLuminousFalse">Sim</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" [ngClass]="{'col-md-6' : !standArt.isHasTotem, 'col-md-4' : standArt.isHasTotem}">
                        <div class="card p-4">
                            <div class="config_top">
                                <div class="d-flex align-items-center flex-wrap">
                                    <h2 class="page-header__title mb-3 pr-4">
                                        <strong>Totem foi aplicado?</strong>
                                    </h2>
                                </div>
                            </div>
                            <div class="d-flex">
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" class="custom-control-input" id="totemAdd" name="totemAdd"
                                           [value]="false"
                                           [(ngModel)]="standArt.isTotemApplied"
                                           (ngModelChange)="setTotemAdd(false)"
                                           [checked]="standArt.isTotemApplied === false"/>
                                    <label class="custom-control-label" for="totemAdd">Não</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" class="custom-control-input" id="totemAdd2" name="totemAdd"
                                           [value]="true"
                                           [(ngModel)]="standArt.isTotemApplied"
                                           (ngModelChange)="setTotemAdd(true)"
                                           [checked]="standArt.isTotemApplied === true"/>
                                    <label class="custom-control-label" for="totemAdd2">Sim</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" class="custom-control-input" id="totemAdd3" name="totemAdd"
                                           [value]="null"
                                           [(ngModel)]="standArt.isTotemApplied"
                                           (ngModelChange)="setTotemAdd(null)"
                                           [checked]="standArt.isTotemApplied === null"/>
                                    <label class="custom-control-label" for="totemAdd3">N/A</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--PROJETOS-->
                <ng-container *ngIf="false">
                    <div class="config_top mt-5 mb-2" *ngIf="this.standArt.createdBy?.isProject">
                        <div class="d-flex w-100">
                            <div class="d-flex align-items-center status-bar {{getStatusCurrentProject(searchResaleResult)}}">
                                <div class="status-bar-bg">
                                    <svg-icon
                                            src="assets/svg/branches-{{(getStatusCurrentProject(searchResaleResult)) === 'received' ? 'analysis' : getStatusCurrentProject(searchResaleResult)}}.svg"></svg-icon>
                                    <span class="pl-1 text-capitalize">{{(getStatusCurrentProject(searchResaleResult)) | translate}}</span>
                                </div>
                            </div>
                            <h2 class="page-header__title large flex-grow-1">
                                <strong>Projeto</strong>
                            </h2>
                        </div>
                    </div>
                    <ng-container *ngFor="let f of standArt?.item let i = index">
                        <div class="question_photo" *ngIf="f.type === 'projects'">
                            <div class="p-3">
                                <div class="photo_row row">
                                    <ng-container *ngFor="let p of f.images; let pI = index;">
                                        <div class="photo mb-xl-5 col-xl-6 col-xxl-3">
                                            <img *ngIf="!isNullOrUndefined(p.url)" class="photo_img"
                                                 (click)="open(p)"
                                                 src="{{p.url}}"
                                                 alt="">
                                            <div *ngIf="isNullOrUndefined(p.url)" class="photo_img">
                                                <svg id="project_add_photo_alternate_black_24dp" xmlns="http://www.w3.org/2000/svg"
                                                     width="46" height="46" viewBox="0 0 46 46">
                                                    <path id="Caminho_134" data-name="Caminho 134" d="M0,0H46V46H0Z"
                                                          fill="none"/>
                                                    <path id="Caminho_135" data-name="Caminho 135"
                                                          d="M37.538,7.75H33.667V3.878A1.881,1.881,0,0,0,31.788,2h-.058a1.886,1.886,0,0,0-1.9,1.878V7.75H25.981a1.91,1.91,0,0,0-1.9,1.878v.058a1.89,1.89,0,0,0,1.9,1.9h3.852v3.853a1.873,1.873,0,0,0,1.9,1.878h.058a1.881,1.881,0,0,0,1.878-1.878V11.583h3.872A1.881,1.881,0,0,0,39.417,9.7V9.628A1.881,1.881,0,0,0,37.538,7.75Zm-9.622,7.686V13.5H25.981a3.8,3.8,0,0,1-3.814-3.872,3.682,3.682,0,0,1,.518-1.878H6.833A3.845,3.845,0,0,0,3,11.583v23a3.845,3.845,0,0,0,3.833,3.833h23a3.845,3.845,0,0,0,3.833-3.833V18.713a3.926,3.926,0,0,1-1.955.537A3.833,3.833,0,0,1,27.917,15.436ZM27.84,34.583H8.75a.958.958,0,0,1-.767-1.533l3.8-5.041a.969.969,0,0,1,1.572.038l3.067,4.619,5-6.67a.954.954,0,0,1,1.514-.019l5.654,7.053A.955.955,0,0,1,27.84,34.583Z"
                                                          transform="translate(2.75 1.833)" fill="#00bb7f"/>
                                                </svg>
                                            </div>
                                            <p class="photo_text">
                                                <span>{{p.name}}</span>
                                            </p>
                                            <ng-container *ngIf="!isNullOrUndefined(p.url)">
                                                <div class="divider"></div>
                                                <ng-container *ngIf="f.type === 'projects'">
                                                    <div class="icon_2"
                                                         *ngIf="(getStatusCurrentProject(searchResaleResult) === 'approved' || getStatusCurrentProject(searchResaleResult) === 'received') && (f.status === 'received' || f.status === 'approved' || f.status === 'review')"
                                                         [ngClass]="{'disabled' : (p.currentStatus ==='approved' || p.currentStatus ==='reproved' || p.currentStatus ==='review')}">
                                                        <svg-icon (click)="handlerStatusItem(i,pI,'approved', f)"
                                                                  [ngClass]="{'approved':p.status ==='approved'}"
                                                                  src="assets/svg/accept.svg"></svg-icon>
                                                        <svg-icon (click)="handlerStatusItem(i,pI,'review', f)"
                                                                  [ngClass]="{'review':p.status ==='review'}"
                                                                  src="assets/svg/review.svg"></svg-icon>
                                                    </div>
                                                </ng-container>
                                            </ng-container>

                                        </div>

                                    </ng-container>
                                    <div class="pt-3 p-md-3 w-100"
                                         *ngIf="validateJustifyPhoto(f.images) && standArt.status === 'received' && (getStatusCurrentProject(searchResaleResult) === 'received')">
                                        <div class="input-material input_adesion">
                                            <input id="project_{{f.id}}" class="form-control js-validate" type="text"
                                                   required/>
                                            <label for="project_{{f.id}}">
                                                <span>Justificativa</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>

                <!--FACHADA CABEÇALHO-->
                <div class="config_top mt-5 mb-2">
                    <div class="d-flex w-100">
                        <div class="d-flex align-items-center status-bar {{standArt.frontStatus}}">
                            <div class="status-bar-bg">
                                <svg-icon
                                        src="assets/svg/branches-{{(standArt.frontStatus) === 'received' ? 'analysis' : standArt.frontStatus}}.svg"></svg-icon>
                                <span class="pl-1 text-capitalize">{{(standArt.frontStatus) | translate}}</span>
                            </div>
                        </div>
                        <h2 class="page-header__title large flex-grow-1">
                            <strong>Fachada</strong>
                        </h2>
                        <div class="d-flex" *ngIf="standArt.type === 'standardization'">
                            <div>Placa Pendente</div>
                            <div class="custom-control custom-radio ml-2">
                                <input type="radio" id="customRadio10" name="customRadio" class="custom-control-input"
                                       [value]="true"
                                       [(ngModel)]="standArt.isPlatePending"
                                       (ngModelChange)="setPlatePending(true)"
                                       [checked]="standArt.isPlatePending === true"/>
                                <label class="custom-control-label" for="customRadio10">Sim</label>
                            </div>
                            <div class="custom-control custom-radio mx-3">
                                <input type="radio" id="customRadio22" name="customRadio" class="custom-control-input"
                                       [value]="false"
                                       [(ngModel)]="standArt.isPlatePending"
                                       (ngModelChange)="setPlatePending(false)"
                                       [checked]="standArt.isPlatePending === false"/>
                                <label class="custom-control-label" for="customRadio22">Não</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input type="radio" id="customRadio23" name="customRadio" class="custom-control-input"
                                       [value]="null"
                                       [(ngModel)]="standArt.isPlatePending"
                                       (ngModelChange)="setPlatePending(null)"
                                       [checked]="standArt.isPlatePending === null"/>
                                <label class="custom-control-label" for="customRadio23">N/A</label>
                            </div>
                        </div>
                        <!--<div class="custom-control custom-switch" *ngIf="standArt.type === 'standardization'">
                            <input [checked]="standArt.isPlatePending"
                                   [value]="true"
                                   (change)="actionChangeBrandItem($event, f)"
                                   type="checkbox" class="custom-control-input"
                                   id="check_standardization_plate" name="check_standardization_plate">
                            <label class="custom-control-label custom-control-label-small"
                                   for="check_standardization_plate">Placa Pendente</label>
                        </div>-->
                    </div>
                </div>

                <!--FACHADA E FROTA -->
                <ng-container *ngFor="let f of standArt?.item let i = index">
                    <div class="question_photo" *ngIf="f.type !== 'projects'">

                        <!--ITEM HEADER - TITULO E OPÇÕES RADIO -->
                        <div class="row align-items-center">
                            <div class="w-100 d-flex justify-content-between flex-wrap">
                                <div class="d-flex flex-grow-1 justify-content-start align-items-center">

                                    <h2 class="page-header__title" *ngIf="f.type !== 'front-wall'">
                                        <span class="page-title ml-3">{{getItemNameByType(f?.type)}}
                                            <small *ngIf="f.type !== 'projects'">#{{f?.id.substring(0, 4)}}</small></span>
                                    </h2>
                                    <div class="d-flex align-items-center mx-3" *ngIf="f.type !== 'front-wall' && f.type !== 'projects'">
                                        <span class="photo-item-text-trash d-flex align-items-center">
                                            <span class="no-wrap mr-2">Placa:</span>
                                            <input type="text" style="width: 100px"
                                                   maxlength="8" [disabled]="standArt.fleetStatus !== 'received'"
                                                   [(ngModel)]="f.plate"
                                                   class="form-control form-control-sm text-primary text-uppercase"/>
                                        </span>
                                        <!--                                        <a href="javascript:void('')" class="svg svg-primary ml-1" (click)="onUpdatePlate(f);"-->
                                        <!--                                        *ngIf="f.status === 'received' || f.status === 'pending' || (getStatusCurrentFleet(searchResaleResult) === 'received' || getStatusCurrentFleet(searchResaleResult) === 'pending')">-->
                                        <!--                                            <svg-icon [svgStyle]="{ 'width.px':20, 'height.px':20 }"-->
                                        <!--                                                      src="assets/svg/disquet.svg"></svg-icon>-->
                                        <!--                                        </a>-->
                                        <a class="btn btn-sm btn-info text-white mx-4" href="javascript:void('');"
                                           *ngIf="f.services.length > 0"
                                           (click)="addService(f)">Serviços</a>
                                    </div>
                                </div>
                                <span class="d-flex flex-grow-1 justify-content-end align-items-center mt-2">

                                    <!--<div class="d-flex align-items-center mx-3" *ngIf="(f?.type !== 'front-wall' && f?.isStandartized && standArt.type === 'adhesion') || (standArt.type === 'standardization' && f?.type !== 'front-wall')">
                                        <span class="photo-item-text-trash d-flex align-items-center">
                                            <span class="no-wrap mr-2">Marca:</span>
                                            <select name="profile" id="profileFilter" [(ngModel)]="f.brand" style="width: 100px"
                                                    [disabled]="standArt.status !== 'received' || standArt.fleetStatus !== 'received'"
                                                    class="form-control form-control-sm text-primary text-capitalize">
                                                <option [ngValue]="null">Selecione</option>
                                                <option [ngValue]="'liquigas'">Liquigás</option>
                                                <option [ngValue]="'copagaz'">Copagáz</option>
                                            </select>
                                        </span>
                                    </div>-->

                                    <div class="custom-control custom-switch"
                                         *ngIf="f?.type !== 'front-wall' && f?.type !== 'projects' && standArt?.type == 'adhesion'">
                                        <input [checked]="f?.brand === 'BR Petrobras'"
                                               [value]="'BR Petrobras'"
                                               (change)="actionChangeBrandItem($event, f)"
                                               type="checkbox" class="custom-control-input"
                                               id="check_force_standardization_{{i}}"
                                               name="check_force_standardization_{{i}}">
                                        <label class="custom-control-label custom-control-label-small"
                                               for="check_force_standardization_{{i}}">Possuí a marca antiga BR Petrobrás?</label>
                                    </div>

                                    <div class="custom-control custom-switch ml-3" *ngIf="standArt?.type == 'adhesion'">
                                        <input (change)="actionChangeIsStandardization($event, f)"
                                               [checked]="f?.isStandartized"
                                               [disabled]="(standArt?.type == 'standardization' && f?.isStandartized) || (f?.type === 'front-wall' && standArt?.frontStatus !== 'received') || (f?.type !== 'front-wall' && standArt?.fleetStatus !== 'received')"
                                               type="checkbox" class="custom-control-input"
                                               id="check_force_{{i}}" name="check_force_{{i}}">
                                        <label class="custom-control-label custom-control-label-small"
                                               for="check_force_{{i}}">Padronizada</label>
                                    </div>

                                    <div class="custom-control custom-switch ml-3" *ngIf="f?.type !== 'front-wall' && f?.type !== 'projects'">
                                        <input (change)="actionChangeIsGreen($event, f)"
                                               [checked]="f?.isGreen"
                                               [disabled]="(standArt?.type == 'standardization' && f?.isGreen) || standArt?.status !== 'received' || standArt?.fleetStatus !== 'received'"
                                               type="checkbox" class="custom-control-input"
                                               id="check_{{i}}" name="check_{{i}}">
                                        <label class="custom-control-label custom-control-label-small"
                                               for="check_{{i}}">Padronização em Verde</label>
                                    </div>

                                </span>
                            </div>
                        </div>

                        <!--ITEM IMAGENS -->
                        <div class="p-3">
                            <div class="photo_row row">
                                <ng-container *ngFor="let p of f.images; let pI = index;">
                                    <div class="photo mb-xl-5 col-xl-6 col-xxl-3">
                                        <img *ngIf="!isNullOrUndefined(p.url)" class="photo_img"
                                             (click)="open(p)"
                                             src="{{p.url}}"
                                             alt="">
                                        <div *ngIf="isNullOrUndefined(p.url)" class="photo_img">
                                            <svg id="add_photo_alternate_black_24dp" xmlns="http://www.w3.org/2000/svg"
                                                 width="46" height="46" viewBox="0 0 46 46">
                                                <path id="Caminho_134" data-name="Caminho 134" d="M0,0H46V46H0Z"
                                                      fill="none"/>
                                                <path id="Caminho_135" data-name="Caminho 135"
                                                      d="M37.538,7.75H33.667V3.878A1.881,1.881,0,0,0,31.788,2h-.058a1.886,1.886,0,0,0-1.9,1.878V7.75H25.981a1.91,1.91,0,0,0-1.9,1.878v.058a1.89,1.89,0,0,0,1.9,1.9h3.852v3.853a1.873,1.873,0,0,0,1.9,1.878h.058a1.881,1.881,0,0,0,1.878-1.878V11.583h3.872A1.881,1.881,0,0,0,39.417,9.7V9.628A1.881,1.881,0,0,0,37.538,7.75Zm-9.622,7.686V13.5H25.981a3.8,3.8,0,0,1-3.814-3.872,3.682,3.682,0,0,1,.518-1.878H6.833A3.845,3.845,0,0,0,3,11.583v23a3.845,3.845,0,0,0,3.833,3.833h23a3.845,3.845,0,0,0,3.833-3.833V18.713a3.926,3.926,0,0,1-1.955.537A3.833,3.833,0,0,1,27.917,15.436ZM27.84,34.583H8.75a.958.958,0,0,1-.767-1.533l3.8-5.041a.969.969,0,0,1,1.572.038l3.067,4.619,5-6.67a.954.954,0,0,1,1.514-.019l5.654,7.053A.955.955,0,0,1,27.84,34.583Z"
                                                      transform="translate(2.75 1.833)" fill="#00bb7f"/>
                                            </svg>
                                        </div>
                                        <p class="photo_text">
                                            <span>{{p.name}}</span>
                                        </p>
                                        <ng-container *ngIf="!isNullOrUndefined(p.url)">
                                            <div class="divider"></div>

                                            <ng-container *ngIf="f.type === 'front-wall'">
                                                <div class="icon_2"
                                                     *ngIf="(getStatusCurrent(searchResaleResult) === 'approved' || getStatusCurrent(searchResaleResult) === 'received') && (f.status === 'received' || f.status === 'approved' || f.status === 'review')"
                                                     [ngClass]="{'disabled' : (p.currentStatus ==='approved' || p.currentStatus ==='reproved' || p.currentStatus ==='review')}">
                                                    <svg-icon (click)="handlerStatusItem(i,pI,'approved', f)"
                                                              [ngClass]="{'approved':p.status ==='approved'}"
                                                              src="assets/svg/accept.svg"></svg-icon>
                                                    <svg-icon (click)="handlerStatusItem(i,pI,'review', f)"
                                                              [ngClass]="{'review':p.status ==='review'}"
                                                              src="assets/svg/review.svg"></svg-icon>
                                                </div>
                                            </ng-container>

                                            <ng-container *ngIf="f.type !== 'front-wall' && f.type !== 'projects'">
                                                <div class="icon_2"
                                                     *ngIf="((standArt.createdBy.isCreatedStandardizationFromAdhesion && getStatusCurrentFleet(searchResaleResult, standArt) === 'pending') || getStatusCurrentFleet(searchResaleResult, standArt) === 'approved' || getStatusCurrentFleet(searchResaleResult, standArt) === 'received') && (f.status === 'received' || f.status === 'approved' || f.status === 'review')"
                                                     [ngClass]="{'disabled' : (p.currentStatus ==='approved' || p.currentStatus ==='reproved' || p.currentStatus ==='review')}">
                                                    <svg-icon (click)="handlerStatusItem(i,pI,'approved', f)"
                                                              [ngClass]="{'approved':p.status ==='approved'}"
                                                              src="assets/svg/accept.svg"></svg-icon>
                                                    <svg-icon (click)="handlerStatusItem(i,pI,'review', f)"
                                                              [ngClass]="{'review':p.status ==='review'}"
                                                              src="assets/svg/review.svg"></svg-icon>
                                                </div>
                                            </ng-container>
                                        </ng-container>

                                    </div>

                                </ng-container>
                                <div class="pt-3 p-md-3 w-100"
                                     *ngIf="validateJustifyPhoto(f.images) && standArt.status === 'received' && (f.type === 'front-wall' ? getStatusCurrent(searchResaleResult, standArt) === 'received' : (getStatusCurrentFleet(searchResaleResult, standArt) === 'received' || (standArt.createdBy.isCreatedStandardizationFromAdhesion && getStatusCurrentFleet(searchResaleResult, standArt) === 'pending')))">
                                    <div class="input-material input_adesion">
                                        <input id="{{f.id}}" class="form-control js-validate" type="text"
                                               required/>
                                        <label for="{{f.id}}">
                                            <span>Justificativa</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--ITEM FORNECEDOR FACHADA -->
                        <div class="photo_row row"
                             *ngIf="standArt.type === 'standardization' && f.type === 'front-wall' && (!isNullOrUndefined(documentsAuthorization[documentsAuthorization.length - 1]?.url) || !isNullOrUndefined(standArt.amountMetersPainted) || !isNullOrUndefined(standArt.justification) || !isNullOrUndefined(standArt.justificationVisit))">
                            <div class="col-12">
                                <div class="card card-primary-dark">
                                    <div class="row p-3">
                                        <div class="col-12">
                                            <h4 class="page-header__title mb-4">
                                                <strong>Fornecedor da Fachada</strong>
                                            </h4>
                                        </div>
                                    </div>

                                    <div class="row w-100 px-3 pb-4 mb-2 text-center">
                                        <div class="col-12">
                                            <div class="card mb-3">
                                                <div class="card-body">
                                                    <div class="d-flex justify-content-between">
                                                        <h4 class="page-header__title small mb-0">
                                                            <strong>Visita Vazia</strong>
                                                        </h4>
                                                        <strong>{{isNullOrUndefined(standArt.justificationVisit) ? 'Não' : 'Sim'}}</strong>
                                                    </div>
                                                    <div class="text-left"
                                                         *ngIf="!isNullOrUndefined(standArt.justificationVisit)">
                                                        <p class="mt-3 mb-1"><strong>Justificativa:</strong></p>
                                                        <p>{{standArt.justificationVisit}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <ng-container *ngIf="!isNullOrUndefined(standArt.justification)">
                                            <div class="col-12">
                                                <div class="card mb-3">
                                                    <div class="card-body">
                                                        <div class="d-flex justify-content-between">
                                                            <h4 class="page-header__title small mb-0">
                                                                <strong>Revenda recusou a padronização</strong>
                                                            </h4>
                                                            <strong>{{isNullOrUndefined(standArt.justification) ? 'Não' : 'Sim'}}</strong>
                                                        </div>
                                                        <div class="text-left"
                                                             *ngIf="!isNullOrUndefined(standArt.justification)">
                                                            <p class="mt-3 mb-1"><strong>Justificativa:</strong></p>
                                                            <p>{{standArt.justification}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>

                                        <ng-container
                                                *ngIf="isNullOrUndefined(standArt.justification) && isNullOrUndefined(standArt.justificationVisit)">
                                            <div class="col-12">
                                                <div class="card mb-3">
                                                    <div class="card-body">
                                                        <div class="d-flex justify-content-between">
                                                            <h4 class="page-header__title small mb-0">
                                                                <strong>Troca de placa e/ou testeira</strong>
                                                            </h4>
                                                            <strong>{{!standArt.isSwapPlate ? 'Não' : 'Sim'}}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 mb-4">
                                                <hr/>
                                            </div>
                                            <div class="col-12 col-md-6"
                                                 *ngIf="!isNullOrUndefined(standArt.amountMetersPainted) && !standArt.isSwapPlate">
                                                <h4 class="page-header__title small mb-4">
                                                    <strong>Metragem</strong>
                                                </h4>
                                                <p class="mb-5">Total em m²
                                                    <strong>{{standArt.amountMetersPainted}}</strong></p>
                                                <div class="row">
                                                    <div class="col-10 offset-1">
                                                        <ng-container>
                                                            <div class="divider"></div>
                                                            <div class="icon_2"
                                                                 [ngClass]="{'disabled' : ((standArt.metersStatus ==='approved' || standArt.metersStatus === 'review') || (getStatusCurrent(searchResaleResult) === 'approved' || getStatusCurrent(searchResaleResult) === 'review'))}">
                                                                <svg-icon src="assets/svg/accept.svg"
                                                                          (click)="handlerStatusDimension(standArt.metersStatus, 'approved')"
                                                                          [ngClass]="{'approved': dimensionStatus === 'approved'}"></svg-icon>
                                                                <svg-icon src="assets/svg/review.svg"
                                                                          (click)="handlerStatusDimension(standArt.metersStatus, 'review')"
                                                                          [ngClass]="{'review': dimensionStatus === 'review'}"></svg-icon>
                                                            </div>
                                                        </ng-container>
                                                        <div class="pt-3 w-100"
                                                             *ngIf="dimensionStatus === 'review' && (getStatusCurrent(searchResaleResult) === 'received')">
                                                            <div class="input-material input_adesion">
                                                                <input id="providerDimension" name="providerDimension"
                                                                       class="form-control js-validate js-validate-dimension"
                                                                       type="text" required/>
                                                                <label for="providerDimension">
                                                                    <span>Justificativa</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <h4 class="page-header__title small mb-4">
                                                    <strong>Termo de Autorização</strong>
                                                </h4>

                                                <p class="text-center text-muted">Documento não enviado</p>

                                                <ng-container
                                                        *ngIf="!isNullOrUndefined(documentsAuthorization[documentsAuthorization.length - 1]?.url)">
                                                    <a href="{{documentsAuthorization[documentsAuthorization.length - 1]?.url}}"
                                                       target="_blank"
                                                       class="d-flex align-items-center justify-content-center flex-column">
                                                        <svg-icon src="assets/svg/file-double.svg" [stretch]="true"
                                                                  [svgStyle]="{'width.px': 40, 'height.px': 50}"></svg-icon>
                                                        <span class="text-primary">Visualizar</span>
                                                    </a>
                                                    <div class="row">
                                                        <div class="col-10 offset-1">
                                                            <ng-container>
                                                                <div class="divider"></div>
                                                                <div class="icon_2"
                                                                     [ngClass]="{'disabled' : ((documentsAuthorization[documentsAuthorization.length - 1]?.currentStatus ==='approved' || documentsAuthorization[documentsAuthorization.length - 1]?.currentStatus ==='review') || (getStatusCurrent(searchResaleResult) === 'approved' || getStatusCurrent(searchResaleResult) === 'review'))}">
                                                                    <svg-icon src="assets/svg/accept.svg"
                                                                              (click)="handlerStatusDocumentAuthorization(documentsAuthorization[documentsAuthorization.length - 1], 'approved')"
                                                                              [ngClass]="{'approved': documentsAuthorization[documentsAuthorization.length - 1]?.status === 'approved'}"></svg-icon>
                                                                    <svg-icon src="assets/svg/review.svg"
                                                                              (click)="handlerStatusDocumentAuthorization(documentsAuthorization[documentsAuthorization.length - 1], 'review')"
                                                                              [ngClass]="{'review': documentsAuthorization[documentsAuthorization.length - 1]?.status === 'review'}"></svg-icon>
                                                                </div>
                                                            </ng-container>
                                                            <div class="pt-3 w-100"
                                                                 *ngIf="documentsAuthorization[documentsAuthorization.length - 1]?.status ==='review' && (getStatusCurrent(searchResaleResult) === 'received')">
                                                                <div class="input-material input_adesion">
                                                                    <input id="providerAuthorization"
                                                                           name="providerAuthorization"
                                                                           class="form-control js-validate js-validate-authorization"
                                                                           type="text" required/>
                                                                    <label for="providerAuthorization">
                                                                        <span>Justificativa</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--FROTA -->
                        <ng-container *ngIf="f.type === 'front-wall' && searchResaleResult.isFleet && countFleet > 0">
                            <div class="d-flex mt-5">
                                <div class="d-flex align-items-center status-bar {{standArt.fleetStatus}}">
                                    <div class="status-bar-bg">
                                        <svg-icon
                                                src="assets/svg/branches-{{(standArt.fleetStatus) === 'received' ? 'analysis' : standArt.fleetStatus}}.svg"></svg-icon>
                                        <span class="pl-1 text-capitalize">{{(standArt.fleetStatus) | translate}}</span>
                                    </div>
                                </div>
                                <h2 class="page-header__title large">
                                    <strong>Frota</strong>
                                </h2>
                            </div>


                            <div class="row my-4">
                                <div class="col-12">
                                    <div class="card card-primary-dark">
                                        <div class="row p-3">
                                            <div class="col-12">
                                                <h4 class="page-header__title mb-4">
                                                    <strong>Fornecedor da Frota</strong>
                                                </h4>
                                            </div>
                                        </div>

                                        <div class="row w-100 px-3 pb-4 mb-2 text-center">
                                            <div class="col-12">
                                                <div class="card mb-3">
                                                    <div class="card-body">
                                                        <div class="d-flex justify-content-between flex-column flex-md-row">
                                                            <h4 class="page-header__title small mb-0 pl-0">
                                                                <strong>Padronização Realizada</strong>
                                                            </h4>
                                                            <strong>{{standArt.isStandardizationPerformed === false || standArt.isStandardizationPerformed == null ? 'Não' : 'Sim'}}</strong>
                                                        </div>
                                                        <hr/>
                                                        <div class="row text-left text-primary"
                                                             *ngIf="standArt.isStandardizationPerformed">
                                                            <!--<div class="col-12 col-md-6">
                                                                <strong>Padronização Realizada Por</strong>
                                                                <br> {{standArt.standardizationPerformedBy === 'resale' ? 'Revenda' : 'Fornecedor'}}
                                                            </div>-->
                                                            <div class="col-12 col-md-12">
                                                                <strong>Data do Serviço</strong>
                                                                <br> {{standArt.fleetServiceAt | date: 'dd/MM/yyyy'}}
                                                            </div>
                                                        </div>
                                                        <div class="text-left text-primary"
                                                             *ngIf="standArt.isStandardizationPerformed === false">
                                                            <p class="mt-3 mb-1"><strong>Justificativa:</strong></p>
                                                            <p>{{standArt.justificationFleetVisit}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="card mb-3">
                                                    <div class="card-body">
                                                        <div class="d-flex justify-content-between flex-column flex-md-row">
                                                            <h4 class="page-header__title small mb-0 pl-0">
                                                                <strong>Termo de Garantia</strong>
                                                            </h4>
                                                            <div class="d-flex justify-content-end">
                                                                <a *ngFor="let x of documentsFleet" href="{{x.url}}" target="_blank"
                                                                   class="d-flex align-items-center justify-content-center flex-column mx-2">
                                                                    <svg-icon src="assets/svg/file-double.svg"
                                                                              [stretch]="true"
                                                                              [svgStyle]="{'width.px': 20, 'height.px': 30}"></svg-icon>
                                                                    <span class="text-primary">Visualizar</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row w-100 mt-5 mb-2">
                                <div class="col-12">
                                    <h2 class="page-header__title large mb-4 pr-4">
                                        <strong>Termo de Frota</strong>
                                    </h2>
                                </div>
                                <div class="col-12">
                                    <div *ngIf="documents.length === 0">
                                        <p class="text-center py-2">Não enviado</p>
                                    </div>
                                    <div class="row" *ngFor="let x of documents; let i = index;">
                                        <ng-container *ngIf="i === (documents.length - 1)">
                                            <div class="col-12 col-md-6">
                                                <a href="{{x.url}}" target="_blank"
                                                   class="d-flex align-items-center justify-content-center flex-column">
                                                    <svg-icon src="assets/svg/file-double.svg" [stretch]="true"
                                                              [svgStyle]="{'width.px': 40, 'height.px': 50}"></svg-icon>
                                                    <span class="text-primary">Visualizar</span>
                                                </a>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <ng-container>
                                                    <div class="divider"></div>
                                                    <div class="icon_2"
                                                         [ngClass]="{'disabled' : ((x.currentStatus ==='approved' || x.currentStatus ==='review') || (getStatusCurrentFleet(searchResaleResult) === 'approved' || getStatusCurrentFleet(searchResaleResult) === 'review'))}">
                                                        <svg-icon src="assets/svg/accept.svg"
                                                                  (click)="handlerStatusDocument(x, 'approved')"
                                                                  [ngClass]="{'approved': x.status === 'approved'}"></svg-icon>
                                                        <svg-icon src="assets/svg/review.svg"
                                                                  (click)="handlerStatusDocument(x, 'review')"
                                                                  [ngClass]="{'review': x.status === 'review'}"></svg-icon>
                                                    </div>
                                                </ng-container>

                                                <div class="pt-3 w-100"
                                                     *ngIf="x.status ==='review' && (getStatusCurrentFleet(searchResaleResult) === 'received' || (standArt.createdBy.isCreatedStandardizationFromAdhesion && getStatusCurrentFleet(searchResaleResult) === 'pending'))">
                                                    <div class="input-material input_adesion">
                                                        <input id="documentJusitfy"
                                                               class="form-control js-validate js-validate-term-fleet"
                                                               type="text" required/>
                                                        <label for="documentJusitfy">
                                                            <span>Justificativa</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>

                            </div>
                        </ng-container>
                        
                    </div>
                </ng-container>
            </div>
        </div>
        <!--Comentarios-->
        <div class="col-xl-4 col-xxl-4">
            <div class="d-flex flex-column justify-content-between pt-3 h-100">
                <div class="comments_card pt-3">
                    <h2 class="page-header__title large">
                        <strong>Comentários</strong>
                    </h2>
                    <div class="scroll_secondary pr-2" style=" overflow-y: auto;">
                        <div class="comment" *ngFor="let m of standartMessages">
                            <div class="top">
                                <!--<div class="d-flex align-items-center">
                                    <svg-icon class="comment_icon" src="assets/svg/ruler.svg"></svg-icon>
                                    <h4 class="comments_card_title">
                                        <strong>{{m.title}}</strong>
                                    </h4>
                                </div>-->
                                <p>{{m.createdAt|date:'dd/MM/yyyy'}}</p>
                            </div>
                            <div class="comment_title">{{m.title}}</div>
                            <p>{{m.message}}</p>
                            <div *ngIf="isNullOrUndefined(m.reply) && isNullOrUndefined(m.createdBy?.name)" class="gray_box">
                                <p (click)="handlerResponse(m)" class="mb-0 text-center is-cursor text-primary font-weight-bold">Responder</p>
                            </div>
                            <div *ngIf="m.reply&&isNullOrUndefined" class="gray_box">
                                Resposta:
                                <b>{{m.reply}}</b>
                            </div>

                        </div>
                    </div>

                    <div class="d-flex flex-grow-1 justify-content-center align-items-center"
                         *ngIf="isNullOrUndefined(standartMessages)">
                        <div>Nenhum comentário registrada</div>
                    </div>
                </div>


                <a *ngIf="standArt.status === 'received'" (click)="onValidate()"
                   class="btn mt-3 btn-block btn-primary btn-lg text-white">Salvar Avaliação</a>


                <!--*ngIf="standArt.status === 'approved' && standArt.type ==='standardization'"-->
                <a (click)="onRollback()" *ngIf="standArt.status === 'approved' && standArt.type ==='standardization'"
                   class="btn mt-3 btn-block btn-danger btn-lg text-white">Resetar</a>

                <a (click)="onRollbackTeam()"
                   class="btn mt-3 btn-block btn-danger btn-lg text-white">Desvincular Equipe</a>
            </div>
        </div>
    </div>
</div>


<div class="modal-custom modal fade" id="responseFleet" data-keyboard="false"
     data-backdrop="static" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-bottom_card p-4">
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <h6 class="modal-bottom_title title_big text-uppercase">Quem será responsável pela
                                padronização?</h6>
                        </div>
                        <div class="col-auto">
                            <a href="javascript:void(0)" (click)="closeModal('responseFleet')">
                                <svg-icon class="modal-bottom_close" src="assets/svg/close.svg"></svg-icon>
                            </a>
                        </div>
                    </div>
                    <div class="modal-bottom_card_radio radio-register_custom radio-items mb-4">
                        <div class="form-check d-flex mb-2 align-items-center">
                            <input class="form-check-input is-cursor" type="radio" name="inlineRadioOptions"
                                   id="inlineRadio1"
                                   [(ngModel)]="modelResponsible"
                                   [checked]="searchResaleResult.responsibleStandardization === 'provider'"
                                   value="provider">
                            <label class="form-check-label" for="inlineRadio1"></label>
                            <span class="ml-3">Copa Energia</span>
                        </div>
                        <div class="form-check d-flex mb-2">
                            <input class="form-check-input is-cursor" type="radio" name="inlineRadioOptions"
                                   id="inlineRadio2"
                                   [(ngModel)]="modelResponsible"
                                   [checked]="searchResaleResult.responsibleStandardization === 'resale'"
                                   value="resale">
                            <label class="form-check-label" for="inlineRadio2"></label>
                            <span class="ml-3">Revenda</span>
                        </div>
                        <div class="form-check d-flex mb-2">
                            <input class="form-check-input is-cursor" type="radio" name="inlineRadioOptions"
                                   id="inlineRadio3"
                                   [(ngModel)]="modelResponsible"
                                   [checked]="searchResaleResult.responsibleStandardization === 'dealing'"
                                   value="dealing">
                            <label class="form-check-label" for="inlineRadio3"></label>
                            <span class="ml-3">Em negociação</span>
                        </div>
                    </div>
                    <a class="btn btn-block mt-5 btn-primary text-white" (click)="onUpdateResponsible()">Salvar</a>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal-custom modal fade" id="modalVehicleChecklist" data-keyboard="false"
     data-backdrop="static" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="row">

                <div class="col-12">
                    <div class="p-4">
                        <div style="gap: 10px"
                             class="d-flex justify-content-between align-items-lg-center">
                            <span></span>
                            <div class="d-flex align-items-center justify-content-between" style="gap: 20px">
                                <a data-dismiss="modal" class="modal_close ml-auto" aria-label="Close">
                                    <svg-icon class="svg-close" src="assets/svg/close-circle.svg"></svg-icon>
                                </a>
                            </div>
                        </div>

                        <div class="tab-custom-primary mt-4">
                            <a href="javascript:void('');" class="tab-custom-primary-item"
                               [ngClass]="{'active' : modalVehicleTab === 0}"
                               (click)="modalVehicleTab = 0">Informações do Veículo</a>
                            <a href="javascript:void('');" class="tab-custom-primary-item"
                               [ngClass]="{'active' : modalVehicleTab === 1}"
                               (click)="modalVehicleTab = 1">Serviços</a>
                        </div>

                        <div *ngIf="modalVehicleTab === 0">
                            <p class="mt-4 mb-2"><strong>Informações do Veículo</strong></p>
                            <div class="row">
                                <div class="col-12 col-md-4">
                                    <div class="input-material">
                                        <select [(ngModel)]="itemService.type" name="modalVehicleType"
                                                id="modalVehicleType" class="form-control" [disabled]="true">
                                            <option [ngValue]="itemService.type">{{itemService.type}}</option>
                                        </select>
                                        <label for="modalVehicleType" class="disabled">Tipo</label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4">
                                    <div class="input-material">
                                        <input [(ngModel)]="itemService.brand" name="modalVehicleBrand"
                                               id="modalVehicleBrand" class="form-control"
                                               [disabled]="true" type="text" required maxlength="150"/>
                                        <label for="modalVehicleBrand" class="disabled">Marca</label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4">
                                    <div class="input-material">
                                        <input [(ngModel)]="itemService.model" name="modalVehicleModel"
                                               id="modalVehicleModel" class="form-control"
                                               [disabled]="true" type="text" required maxlength="150"/>
                                        <label for="modalVehicleModel" class="disabled">Modelo</label>
                                    </div>
                                </div>

                                <div class="col-12 col-md-4">
                                    <div class="input-material">
                                        <input [(ngModel)]="itemService.modelYear" name="modalVehicleModelYear"
                                               id="modalVehicleModelYear"
                                               class="form-control" [disabled]="true"
                                               type="text" required maxlength="4"/>
                                        <label for="modalVehicleModelYear" class="disabled">Ano do Modelo</label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4">
                                    <div class="input-material">
                                        <input [(ngModel)]="itemService.plate" name="modalVehiclePlate"
                                               id="modalVehiclePlate" class="form-control"
                                               type="text" required maxlength="150" [disabled]="true"/>
                                        <label for="modalVehiclePlate" class="disabled">Placa do Veículo</label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4">
                                    <div class="input-material">
                                        <select [(ngModel)]="itemService.oldBrand" name="modalVehicleBrandOld"
                                                id="modalVehicleBrandOld"
                                                class="form-control" [disabled]="true">
                                            <option [ngValue]="undefined">Selecione</option>
                                            <option [ngValue]="true">Sim</option>
                                            <option [ngValue]="false">Não</option>
                                        </select>
                                        <label for="modalVehicleBrandOld" class="disabled">Marca Antiga</label>
                                    </div>
                                </div>

                                <div class="col-12 mb-4">
                                    <hr/>
                                </div>

                                <div class="col-12">
                                    <div class="input-material">
                                        <select [(ngModel)]="itemService.plateTop" name="modalVehicleBrandTop"
                                                id="modalVehicleBrandTop" class="form-control" [disabled]="true">
                                            <option [ngValue]="undefined">Selecione</option>
                                            <option [ngValue]="true">Sim</option>
                                            <option [ngValue]="false">Não</option>
                                        </select>
                                        <label for="modalVehicleBrandTop" class="disabled">Placa no Topo</label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4" *ngIf="itemService.plateTop">
                                    <div class="input-material">
                                        <input [(ngModel)]="itemService.plateMeasurements"
                                               name="modalVehicleBrandTopDimension" id="modalVehicleBrandTopDimension"
                                               class="form-control" [disabled]="true"
                                               type="text" required maxlength="150"/>
                                        <label for="modalVehicleBrandTopDimension" class="disabled">Medidas das
                                            Placas</label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4" *ngIf="itemService.plateTop">
                                    <div class="input-material">
                                        <input [(ngModel)]="itemService.numberPlates"
                                               name="modalVehicleBrandTopQuantity" id="modalVehicleBrandTopQuantity"
                                               class="form-control" [disabled]="true"
                                               type="text" required maxlength="150"/>
                                        <label for="modalVehicleBrandTopQuantity" class="disabled">Quantidade de
                                            Placas</label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4" *ngIf="itemService.plateTop">
                                    <div class="input-material">
                                        <select [(ngModel)]="itemService.frontBack"
                                                name="modalVehicleBrandTopFrontBack" id="modalVehicleBrandTopFrontBack"
                                                class="form-control" [disabled]="true">
                                            <option [ngValue]="undefined">Selecione</option>
                                            <option [ngValue]="true">Sim</option>
                                            <option [ngValue]="false">Não</option>
                                        </select>
                                        <label for="modalVehicleBrandTopFrontBack" class="disabled">Frente e
                                            Verso</label>
                                    </div>
                                </div>
                            </div>

                            <div class="my-4">
                                <a (click)="modalVehicleTab = 1" class="btn btn-primary btn-block text-white"
                                   href="javascript:void(0);">Próximo</a>
                            </div>
                        </div>

                        <div *ngIf="modalVehicleTab === 1">

                            <p class="mt-4 mb-2"><strong>Serviços</strong></p>

                            <p *ngIf="itemServiceList.length === 0" class="text-center py-4">Nenhum item encontrado</p>

                            <div class="row">
                                <div class="col-12" *ngFor="let l of itemServiceList">
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" class="custom-control-input" [disabled]="true"
                                               id="check_list_1" name="check_list_1" checked>
                                        <label class="custom-control-label" for="check_list_1">{{l.description}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="my-2">
                                <hr />
                            </div>

                            <p class="mt-4 mb-2"><strong>Quantidade de aplicações de logo</strong>: {{itemService.amountLogo}}</p>

                            <div class="my-2">
                                <hr />
                            </div>

                            <p class="mt-4 mb-2"><strong>Checklist</strong></p>

                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <a href="{{documentsChecklist[0].url}}" *ngIf="!isNullOrUndefined(documentsChecklist)"
                                       target="_blank" class="is-cursor d-block mt-3 mx-auto text-center">
                                        <svg-icon class="svg-primary" src="assets/svg/file-double.svg"></svg-icon>
                                        <p class="mt-2 text-primary" style="line-height: 1">
                                            Download Checklist</p>
                                    </a>
                                </div>
                                <!--<div class="col-12 col-md-6">
                                    <a href="{{documentsChecklistExit[0].url}}" *ngIf="!isNullOrUndefined(documentsChecklistExit)"
                                       target="_blank" class="is-cursor d-block mt-3 mx-auto text-center">
                                        <svg-icon class="svg-primary" src="assets/svg/file-double.svg"></svg-icon>
                                        <p class="mt-2 text-primary" style="line-height: 1">
                                            Download Checklist Saída</p>
                                    </a>
                                </div>-->
                            </div>


                            <div class="mt-4">
                                <div class="input-material" style="padding-top: 26px;">
                                    <textarea [(ngModel)]="itemService.observation" id="check_list_obs"
                                              style="resize: none;" [disabled]="true"></textarea>
                                    <label for="check_list_obs">Observações:</label>
                                </div>
                            </div>

                            <div class="row my-4">
                                <div class="col-6">
                                    <a (click)="modalVehicleTab = 0" class="btn btn-outline-primary btn-block"
                                       href="javascript:void(0);">Voltar</a>
                                </div>
                                <div class="col-6">
                                    <a (click)="closeModal('modalVehicleChecklist')"
                                       class="btn btn-primary btn-block text-white"
                                       href="javascript:void(0);">Fechar</a>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal-custom modal fade" id="modalComments" data-keyboard="false"
     data-backdrop="static" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content modal_comment">
            <div class="row">

                <div class="col-12 col-lg-4">
                    <div class="modal_col-primary">
                        <a data-dismiss="modal" class="modal_close ml-auto d-lg-none" aria-label="Close">
                            <svg-icon [svgStyle]="{'fill':'white'}" class="svg-close mt-2"
                                      src="assets/svg/close-circle.svg"></svg-icon>
                        </a>
                        <img src="assets/images/logo.png" style="width: 279px;" alt="logo">
                        <h2 class="modal_title text-white mb-3">Mural Liquigás</h2>
                    </div>
                </div>

                <div class="col-12 col-lg-8">
                    <div class="p-4 pr-lg-5">
                        <div style="gap: 10px"
                             class="d-flex justify-content-between align-items-lg-center">
                            <div class="d-flex align-items-center" style="gap: 20px">
                                <svg-icon src="assets/svg/logo2.svg"></svg-icon>
                                <p class="modal_comment_title">{{modalMessage.createdBy?.name}}</p>
                            </div>
                            <div class="d-flex align-items-center justify-content-between" style="gap: 20px">
                                <p class="modal_comment_date mb-0">{{modalMessage.createdAt|date:'dd / MM / yyyy'}}
                                    às {{modalMessage.createdAt|date:'HH:mm'}}</p>
                                <a data-dismiss="modal" class="modal_close ml-auto d-none d-lg-flex" aria-label="Close">
                                    <svg-icon class="svg-close" src="assets/svg/close-circle.svg"></svg-icon>
                                </a>
                            </div>
                        </div>
                        <p class="modal_comment_quest">{{getItemNameByType(modalMessage.type)}}</p>
                        <p class="modal_comment_descriptions">{{modalMessage.message}}</p>
                        <div class="input-material"
                             style="padding-top: 26px;">
                            <textarea id="field1" [(ngModel)]="textResponse" style="resize: none;"></textarea>
                            <label for="field1">Escreva aqui sua mensagem</label>
                        </div>
                        <div>
                            <a (click)="actionResponse()" class="btn btn-primary btn-block mb-4 text-white"
                               href="javascript:void(0);">Enviar</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
