import {environment} from '../../environments/environment';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';
import {NgxSpinnerService} from 'ngx-spinner';
import moment from 'moment';

declare var $: any;

export class BaseComponent {
    listGme = ['GME-SP', 'GME-SUL', 'GME-CO', 'GME-LE', 'GME-NE'];
    listType = ['glp', 'gas'];
    listStatus = ['received', 'approved', 'reproved', 'review'];
    labels: any = {
        previousLabel: '',
        nextLabel: '',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService) {
    }

    setToken(token: string) {
        localStorage.setItem('token', token);
    }

    getTranslate(s: string) {
        if (!this.isNullOrUndefined(s)) {
            return this.translate.instant(s);
        }
    }

    getBaseURL() {
        return environment.base_url;
    }

    switchLanguage(language: string) {
        if (language === 'pt') {
            language = 'pt';
        } else if (language === 'en') {
            language = 'en';
        } else if (language === 'es') {
            language = 'es';
        }
        this.translate.use(language);
    }

    handlerConfirm() {
        $('#modalNoteResume').modal('show');
    }

    showModal(idModal) {
        $('#' + idModal).modal('show');
    }

    closeModal(idModal) {
        $('#' + idModal).modal('hide');
    }

    showMessage(t: string, m: string, type: any = 'warning') {
        Swal.fire({
            title: t,
            html: m,
            icon: type,
            confirmButtonColor: '#032E58',
        });
    }

    confirmMessage(t: string, m: string, callback: any = null, callbackNo?) {
        Swal.fire({
            title: t,
            html: m,
            icon: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            confirmButtonColor: '#032E58',
            cancelButtonColor: '#dc3545'
        }).then((result) => {
            if (!this.isNullOrUndefined(result.value) && result.value === true) {
                if (callback != null) {
                    callback();
                }
            } else {
                if (callbackNo != null) {
                    callbackNo();
                }
            }
        });
    }

    onError(error) {
        this.loading.hide();
        if (error.status === 401) {
            this.router.navigate(['/authenticate']);
            return;
        }

        // const e = JSON.parse(error['error']);

        if (error.status === 400) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), error.error.message, 'warning');
            return;
        }

        if (error.status === 500) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningMsgError'), 'warning');
            return;
        }
    }

    isNullOrUndefined(value) {
        return typeof value === 'undefined' || value == null || value.length === 0;
    }

    validateField(obj) {
        let strError = ``;
        obj.map((e) => {
            if (this.isNullOrUndefined(e.value)) {
                strError += `${e.text}`;
            }
        });
        return strError;
    }

    handlerFlagImage(value) {
        switch (value) {
            case 'Chile':
                return 'chile.svg';
            case 'Peru':
                return 'peru.svg';
            case 'Bolívia':
                return 'bolivia.svg';
            case 'Venezuela':
                return 'venezuela.svg';
            case 'Argentina':
                return 'argentina.svg';
            case 'México':
                return 'mexico.svg';
            case 'Equador':
                return 'equador.svg';
            case 'Guatemala':
                return 'guatemala.svg';
            case 'Brazil':
                return 'brazil.svg';
            case 'Colômbia':
                return 'colombia.svg';
            case 'Uruguai':
                return 'uruguay.svg';
            case 'Paraguay':
                return 'paraguay.svg';
        }
    }

    handlerStepBar(value) {

        switch (value) {
            case 1:
                return 'width: 0%';
            case 2:
                return 'width: 25%';
            case 3:
                return 'width: 50%';
            case 4:
                return 'width: 75%';
            case 5:
                return 'width: 100%';
        }
    }

    handlerTitlesBoxVertical(): void {
        const el = $('.page-content__details__tab');
        if (!el[0]) {
            return;
        }
        el.each((i, x) => {
            $(x).css({width: $(x).parent()[0].getBoundingClientRect().height});
        });
    }

    handlerBoxSize(): void {
        const el = $('.js-set-border');
        if (!el[0]) {
            return;
        }
        el.each((i, x) => {
            const size = $(x).parent()[0].getBoundingClientRect().height;
            $(x)
                .css({
                    height: size
                })
                .find('span').css({
                width: size
            });
        });
    }

    setDatePicker(id: string, callback, date = 30) {
        $('.' + id).daterangepicker({
            startDate: moment().subtract(date, 'days'),
            cancelButtonClasses: 'btn-danger',
            ranges: {
                Hoje: [moment(), moment()],
                Ontem: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                '7 Dias': [moment().subtract(6, 'days'), moment()],
                '30 Dias': [moment().subtract(29, 'days'), moment()]
            },
            locale: this.getObjectLocaleDatePicker()
        }, callback);
    }

    setDatePickerFilter(id: string, callback) {
        $('.' + id).daterangepicker({
            startDate: moment('2023-03-01'),
            cancelButtonClasses: 'btn-danger',
            ranges: {
                Hoje: [moment(), moment()],
                Ontem: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                '7 Dias': [moment().subtract(6, 'days'), moment()],
                '30 Dias': [moment().subtract(29, 'days'), moment()]
            },
            locale: this.getObjectLocaleDatePicker()
        }, callback);
    }

    getObjectLocaleDatePicker() {
        return {
            customRangeLabel: 'Personalizar',
            format: 'DD/MM/YYYY',
            separator: ' - ',
            applyLabel: 'Aplicar',
            cancelLabel: 'Cancelar',
            daysOfWeek: [
                'Dom',
                'Seg',
                'Ter',
                'Qua',
                'Qui',
                'Sex',
                'Sab'
            ],
            monthNames: [
                'Janeiro',
                'Fevereiro',
                'Março',
                'Abril',
                'Maio',
                'Junho',
                'Julho',
                'Agosto',
                'Setembro',
                'Outubro',
                'Novembro',
                'Dezembro'
            ],
            firstDay: 1
        };
    }

    toggleLoader(b: boolean) {
        if (!b) {
            $('#loaderBox').fadeOut();
        } else {
            $('#loaderBox').fadeIn();
        }
    }

    isEMailValid(strEmail) {
        const str = strEmail;
        const exp = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;
        const testResult = exp.test(str);
        if (!testResult) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningMsgEmail'), 'warning');
        }
        return testResult;
    }

    onLogout() {
        this.confirmMessage('Atenção', 'Deseja realmente sair?', () => {
            /*const p = window.location.pathname.split('/');
            const virtualPath = p.length >= 3 ? '/' + p[1] : '';
            const redirectUrl = 'https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=' +
                window.location.origin + virtualPath + '/logout';
            window.location.href = redirectUrl;*/
            this.router.navigate(['/login']);
        });
    }

    getItemNameByType(t: string) {
        switch (t) {
            case 'document':
                return 'Termo de Frota';
            case 'documentAuthorization':
                return 'Termo de Autorização';
            case 'dimension':
                return 'Metragem';
            case 'front-wall':
                return 'Fachada';
            case 'motorcycle':
                return 'Moto';
            case 'pickup':
                return 'Pickup';
            case 'truck':
                return 'Truck Transferência';
            case 'transfer-truck':
                return 'Carreta Transferência';
            case 'vuc':
                return 'VUC';
            case 'projects':
                return 'Projeto';
        }
    }

    downloadHandlerFile(e, fileName) {
        const downLoad = $('#btnDownload');
        downLoad.attr('href', e);
        downLoad.attr('download', fileName + '_' + moment().format('DD-MM-YYYY'));
        setTimeout(() => {
            $('#btnDownload')[0].click();
        });
    }

    setFilterLocal(s: string, o: any) {
        localStorage.setItem(s, JSON.stringify(o));
    }

    getFilterLocal(s: string) {
        return localStorage.getItem(s);
    }

    getResponsibleStandardization(r: string) {
        let x = 'Não enviado';
        switch (r) {
            case 'resale':
                x = 'Revenda';
                break;
            case 'provider':
                x = 'Copa Energia';
                break;
            case 'dealing':
                x = 'Em negociação';
                break;
        }
        return x;
    }

    getStatusCurrent(x: any, s?) {
        if (s) {
            return s.frontStatus;
        }
        return x.processStep === 'adhesion' ? x.frontStatusAdhesion : x.frontStatusStandardization;
    }

    getStatusCurrentFleet(x: any, s?) {
        if (s) {
            return s.fleetStatus;
        }
        return x.processStep === 'adhesion' ? x.fleetStatusAdhesion : x.fleetStatusStandardization;
    }

    getStatusCurrentProject(x: any) {
        console.log('[getStatusCurrentProject]');
        console.log(x);
        if (x) {
            return x.projectStatusStandardization;
        }
    }

    validateFieldZero(obj) {
        let strError = ``;
        obj.map((e) => {
            if (this.isNullOrUndefined(e.value) || e.value === 0) {
                strError += `${e.text}`;
            }
        });
        return strError;
    }
}
