import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    ConsultantStandart,
    CreateStandartMessageGQL,
    CreateStandartMessageInput,
    CreateStandartMessageStandartInput,
    EvalDocumentGQL,
    EvaluateAdhesionGQL,
    EvaluateStandardizationGQL,
    EvaluateStandartInput,
    EvaluateStandartItemImageInput,
    EvaluateStandartItemInput,
    RollbackStandartGQL,
    SearchDocumentsGQL,
    SearchDocumentsQuery,
    SearchResaleGQL,
    SearchResaleQuery,
    Standart,
    StandartGQL, StandartItem,
    StandartItemImage,
    StandartMessage,
    StandartMessagesGQL,
    StandartQuery,
    StandartQueryVariables,
    UpdateBrandStandardItemGQL,
    UpdatePlateStandardItemGQL,
    UpdateResponsibleGQL,
    User
} from "../../../generated/graphql";
import {QueryRef} from "apollo-angular";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {UserService} from "../../service/user.service";
import {Lightbox} from "ngx-lightbox";
import {TranslateService} from "@ngx-translate/core";
import {IAlbum} from "ngx-lightbox/lightbox-event.service";
import {BaseComponent} from "../../base/base.component";

declare var $: any;

@Component({
    selector: 'app-consultant-detail',
    templateUrl: './consultant-detail.component.html',
    styleUrls: ['./consultant-detail.component.scss']
})
export class ConsultantDetailComponent extends BaseComponent implements OnInit, OnDestroy {
    user: User = new User();
    paramId: any;
    standArt: Standart = new Standart();
    standartMessages: StandartMessage[] = [];
    standArtQuery: QueryRef<StandartQuery, StandartQueryVariables>;
    // forceApprove = false;

    searchResaleQuery: QueryRef<SearchResaleQuery>;
    searchResaleResult: ConsultantStandart = new ConsultantStandart();


    documents: any[] = [];
    documentsAuthorization: any[] = [];
    documentsProject: any[] = [];
    searchDocumentsQuery: QueryRef<SearchDocumentsQuery>;
    searchDocumentsAuthorizationQuery: QueryRef<SearchDocumentsQuery>;
    searchDocumentsProjectQuery: QueryRef<SearchDocumentsQuery>;

    dimensionStatus = null;

    constructor(public router: Router,
                public route2: ActivatedRoute,
                public loading: NgxSpinnerService,
                public standArtByIdGQL: StandartGQL,
                public standartMessagesGQL: StandartMessagesGQL,
                private searchResaleGQL: SearchResaleGQL,
                private updateResponsibleGQL: UpdateResponsibleGQL,
                private rollbackStandartGQL: RollbackStandartGQL,
                private updatePlateStandardItemGQL: UpdatePlateStandardItemGQL,
                private searchDocumentsGQL: SearchDocumentsGQL,
                private evalDocumentGQL: EvalDocumentGQL,
                private updateBrandStandardItemGQL: UpdateBrandStandardItemGQL,
                public userService: UserService,
                private lightbox: Lightbox,
                public translate: TranslateService) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data?.id)) {
                    this.user = data as User;
                }
            }, error: err => super.onError(err)
        });
        this.route2.params.subscribe(url => {
            console.log('ROUTE');
            if (!this.isNullOrUndefined(url)) {
                this.paramId = url.id;
                this.loading.show();
                this.standArtQuery = this.standArtByIdGQL.watch({id: this.paramId});
                this.standArtQuery.valueChanges.subscribe(({data}) => {
                    this.standArt = JSON.parse(JSON.stringify(data.standart));
                    if (!this.isNullOrUndefined(this.standArt.metersStatus)) {
                        this.dimensionStatus = this.standArt.metersStatus;
                    }
                    this.searchResaleQuery = this.searchResaleGQL.watch({input: this.standArt.createdBy.id});
                    this.searchResale();
                    // this.modelResponsible = this.standArt.r
                    this.getListMessage();
                    this.loading.hide();
                }, error => super.onError(error));
            }
        });
    }

    ngOnDestroy(): void {
        const el = $('#responseFleet');
        if (el[0]) {
            el.remove();
        }
    }

    getListMessage() {
        // this.loading.show();
        this.standartMessagesGQL.watch({id: this.paramId}).valueChanges.subscribe(({data}) => {
            this.standartMessages = JSON.parse(JSON.stringify(data.standartMessages));
            // this.loading.hide();
        }, error => super.onError(error));
    }

    searchResale() {
        this.searchResaleQuery.valueChanges.subscribe(({data}) => {
            this.searchResaleResult = data.searchResale as ConsultantStandart;
            this.searchDocument();
            this.searchDocumentAuthorization();
            this.searchDocumentProject();
            if (this.isNullOrUndefined(this.searchResaleResult?.type)) {
                this.showModal('responseFleet');
                return;
            }

        });
    }

    open(p: StandartItemImage): void {
        const iAlbum: IAlbum = {
            caption: p.name,
            src: p.url,
            thumb: p.url,
        };
        this.lightbox.open([iAlbum], 0, {
            wrapAround: false,
            showImageNumberLabel: false,
            disableScrolling: true,
            showZoom: false,
            showRotate: false
        });
    }

    validateJustifyPhoto(images: StandartItemImage[]) {
        return images.some(element => element.status === 'reproved' || element.status === 'review');
    }

    actionGetStandart(t: string) {
        let id = null;
        if (t === this.standArt.type) {
            return;
        }
        if (!this.isNullOrUndefined(this.standArt.father)) {
            id = this.standArt.father.id;
        } else if (!this.isNullOrUndefined(this.standArt.sons)) {
            id = this.standArt.sons.id;
        }
        if (this.isNullOrUndefined(id)) {
            return;
        }
        window.location.href = '/consultant/' + id;
    }

    actionChangeIsStandardization(c: any, el) {
        el.isStandartized = c.srcElement.checked;
    }

    actionChangeIsGreen(c: any, el) {
        el.isGreen = c.srcElement.checked;
    }


    searchDocument() {
        this.searchDocumentsQuery = this.searchDocumentsGQL.watch({
            id: this.searchResaleResult.id,
            type: 'certificate'
        });
        this.searchDocumentsQuery.valueChanges.subscribe(({data}) => {
            this.documents = data.searchDocuments;
            this.documents.map(x => x.currentStatus = x.status);
            console.log('this.documents');
            console.log(this.documents);
        }, error => super.onError(error));
    }

    searchDocumentAuthorization() {
        this.searchDocumentsAuthorizationQuery = this.searchDocumentsGQL.watch({
            id: this.searchResaleResult.id,
            type: 'authorization'
        });
        this.searchDocumentsAuthorizationQuery.valueChanges.subscribe(({data}) => {
            this.documentsAuthorization = data.searchDocuments;
            this.documentsAuthorization.map(x => x.currentStatus = x.status);
        }, error => super.onError(error));
    }

    searchDocumentProject() {
        this.searchDocumentsProjectQuery = this.searchDocumentsGQL.watch({
            id: this.searchResaleResult.id,
            type: 'project'
        });
        this.searchDocumentsProjectQuery.valueChanges.subscribe(({data}) => {
            this.documentsProject = data.searchDocuments;
        }, error => super.onError(error));
    }

    actionChangeBrandItem(c: any, f: StandartItem) {
        f.brand = c.srcElement.checked === true ? 'BR Petrobras' : '';
        this.updateBrandStandardItemGQL.mutate({respons: f.brand, input: f.id}).subscribe((data) => {
            // console.log(data);
        });
    }

}
