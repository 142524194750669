<div class="single__page">
    <h1 class="mb-5">
        <img src="assets/images/logo-copaenergia-white.png" alt="">
    </h1>
    <p>
        Você não está logado.
    </p>
    <p>
        <a href="javascript:void('Login');" [routerLink]="['/login']">Clique aqui para <strong>ENTRAR!</strong></a>
    </p>
</div>
