<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <h1 class="page-title">Gestão <span>| Dashboard</span></h1>
            <a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>
            <a href="javascript:void('');" class="btn btn-info text-white" (click)="getExcel()">
                <svg-icon class="svg-white right mr-2" src="assets/svg/excel.svg"></svg-icon>
                Exportar Excel
            </a>
        </div>
        <div class="dashboard">
            <div class="container">

                <section class="row mt-4">
                    <article class="col-12">
                        <div class="card-primary">
                            <div class="card-primary-box">
                                <div class="card-primary-header">
                                    <p class="card-primary-header-title">Gráficos de acesso</p>
                                </div>
                                <div class="card-primary-body">
                                    <div class="card-primary-body-graph js-chart-box">
                                        <google-chart [width]="chartWidth"
                                                      [height]="chartHeight"
                                                      [type]="'Line'"
                                                      [data]="chartAccess"
                                                      [columns]="['Dias',  'Acessos']"
                                                      [options]="options">
                                        </google-chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>

                    <article class="col-12 col-lg-6">
                        <div class="card-primary">
                            <div class="card-primary-box">
                                <div class="card-primary-header">
                                    <p class="card-primary-header-title">Usuários Ativos</p>
                                </div>
                                <div class="card-primary-body">
                                    <div class="row mt-3 user-active">
                                        <div class="col-sm-6 mb-4" *ngFor="let i of activeUsers">
                                            <dl>
                                                <dt class="h6 text-center">{{i.type}}({{i.branding}})</dt>
                                                <dd class="row justify-content-center">
                                                    <dl class="col-auto text-center">
                                                        <dd class="mb-0">{{i.activated}}</dd>
                                                        <dt>Ativo:</dt>
                                                    </dl>
                                                    <dl class="col-auto text-center">
                                                        <dd class="mb-0">{{i.total}}</dd>
                                                        <dt>Total</dt>
                                                    </dl>
                                                </dd>
                                            </dl>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>

                    <article class="col-12 col-lg-6">
                        <div class="card-primary">
                            <div class="card-primary-box">
                                <div class="card-primary-header">
                                    <p class="card-primary-header-title">Revendas por GMR</p>
                                </div>
                                <div class="card-primary-body">
                                    <div class="row justify-content-center mt-4 divider">
                                        <div class="col col-6 col-xxl" *ngFor="let u of usersByGme">
                                            <div class="d-flex flex-column align-items-center">
                                                <p class="dashboard_value">{{u.value}}</p>
                                                <p class="dashboard_key">{{u.key}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>

                    <article class="col-12 col-lg-6">
                        <div class="card-primary">
                            <div class="card-primary-box">
                                <div class="card-primary-header">
                                    <p class="card-primary-header-title">Adesão</p>
                                </div>
                                <div class="card-primary-body">
                                    <div class="row mt-4 divider">
                                        <div class="col col-12 col-xs-6 col-xxl">
                                            <div class="d-flex flex-column align-items-center">
                                                <p class="dashboard_value">{{adhesion.received}}</p>
                                                <p class="dashboard_key">Recebida</p>
                                            </div>
                                        </div>
                                        <div class="col col-12 col-xs-6 col-xxl">
                                            <div class="d-flex flex-column align-items-center">
                                                <p class="dashboard_value">{{adhesion.review}}</p>
                                                <p class="dashboard_key">Em Revisão</p>
                                            </div>
                                        </div>
                                        <div class="col col-12 col-xs-4 col-xxl">
                                            <div class="d-flex flex-column align-items-center">
                                                <p class="dashboard_value">{{adhesion.pending}}</p>
                                                <p class="dashboard_key">Pendente</p>
                                            </div>
                                        </div>
                                        <div class="col col-12 col-xs-4 col-xxl">
                                            <div class="d-flex flex-column align-items-center">
                                                <p class="dashboard_value">{{adhesion.approved}}</p>
                                                <p class="dashboard_key">Aprovado</p>
                                            </div>
                                        </div>
                                        <div class="col col-12 col-xs-4 col-xxl">
                                            <div class="d-flex flex-column align-items-center">
                                                <p class="dashboard_value">{{adhesion.reproved}}</p>
                                                <p class="dashboard_key">Reprovado</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article class="col-12 col-lg-6">
                        <div class="card-primary">
                            <div class="card-primary-box">
                                <div class="card-primary-header">
                                    <p class="card-primary-header-title">Padronização</p>
                                </div>
                                <div class="card-primary-body">
                                    <div class="row mt-4 divider">
                                        <div class="col col-12 col-xs-6 col-xxl">
                                            <div class="d-flex flex-column align-items-center">
                                                <p class="dashboard_value">{{standardization.received}}</p>
                                                <p class="dashboard_key">Recebida</p>
                                            </div>
                                        </div>
                                        <div class="col col-12 col-xs-6 col-xxl">
                                            <div class="d-flex flex-column align-items-center">
                                                <p class="dashboard_value">{{standardization.review}}</p>
                                                <p class="dashboard_key">Em Revisão</p>
                                            </div>
                                        </div>
                                        <div class="col col-12 col-xs-4 col-xxl">
                                            <div class="d-flex flex-column align-items-center">
                                                <p class="dashboard_value">{{standardization.pending}}</p>
                                                <p class="dashboard_key">Pendente</p>
                                            </div>
                                        </div>
                                        <div class="col col-12 col-xs-4 col-xxl">
                                            <div class="d-flex flex-column align-items-center">
                                                <p class="dashboard_value">{{standardization.approved}}</p>
                                                <p class="dashboard_key">Aprovado</p>
                                            </div>
                                        </div>
                                        <div class="col col-12 col-xs-4 col-xxl">
                                            <div class="d-flex flex-column align-items-center">
                                                <p class="dashboard_value">{{standardization.reproved}}</p>
                                                <p class="dashboard_key">Reprovado</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article class="col-12 col-lg-6">
                        <div class="card-primary">
                            <div class="card-primary-box">
                                <div class="card-primary-header">
                                    <p class="card-primary-header-title">Fale Conosco</p>
                                </div>
                                <div class="card-primary-body">
                                    <div class="row mt-4 divider">
                                        <div class="col col-6 col-lg-12 col-xxl-6">
                                            <div class="d-flex flex-column align-items-center">
                                                <p class="dashboard_value">{{contact.total}}</p>
                                                <p class="dashboard_key">Total</p>
                                            </div>
                                        </div>
                                        <div class="col col-6 col-lg-12 col-xxl-6">
                                            <div class="d-flex flex-column align-items-center">
                                                <p class="dashboard_value">{{contact.answered}}</p>
                                                <p class="dashboard_key">Respondido</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>

                    <!--                    <article class="col-12 col-lg-6">-->
                    <!--                        <div class="card-primary">-->
                    <!--                            <div class="card-primary-box">-->
                    <!--                                <div class="card-primary-header">-->
                    <!--                                    <p class="card-primary-header-title">Slot</p>-->
                    <!--                                </div>-->
                    <!--                                <div class="card-primary-body">-->
                    <!--                                    <div class="table-responsive">-->
                    <!--                                        <table class="table">-->
                    <!--                                            <thead>-->
                    <!--                                            <tr>-->
                    <!--                                                <th scope="col">Faixa</th>-->
                    <!--                                                <th scope="col">Slots ocupados</th>-->
                    <!--                                                <th scope="col">Slots</th>-->
                    <!--                                            </tr>-->
                    <!--                                            </thead>-->
                    <!--                                            <tbody>-->
                    <!--                                            <tr *ngFor="let l of lot; let i = index;">-->
                    <!--                                                <td>{{l.range}}</td>-->
                    <!--                                                <td>{{l.occupiedSlots}}</td>-->
                    <!--                                                <td>{{l.slots}}</td>-->
                    <!--                                            </tr>-->
                    <!--                                            &lt;!&ndash;<tr *ngFor="let l of lot; let i = index;">-->
                    <!--                                                <td>{{i === 3 ? 'Faixa 4' : l.range}}</td>-->
                    <!--                                                <td>{{((lot.length - 1) === i) ? 60 : 100}}&lt;!&ndash;{{l.occupiedSlots}}&ndash;&gt;</td>-->
                    <!--                                                <td>{{l.slots}}</td>-->
                    <!--                                            </tr>&ndash;&gt;-->
                    <!--                                            </tbody>-->
                    <!--                                        </table>-->
                    <!--                                    </div>-->
                    <!--                                </div>-->
                    <!--                            </div>-->
                    <!--                        </div>-->
                    <!--                    </article>-->

                </section>
            </div>
        </div>
    </div>
</div>





