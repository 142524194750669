import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "../../service/user.service";
import {AuthInputAdmin, AuthType, AuthTypeAdmin, LoginAdminGQL} from "../../../generated/graphql";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseComponent implements OnInit {
    model: AuthInputAdmin = new AuthInputAdmin();

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                public service: UserService,
                private loginAdminGQL: LoginAdminGQL
    ) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        localStorage.clear();
    }

    actionLogin(): void {
        const arrValidateFields = [
            {value: this.model.email, text: 'E-mail <br />'},
            {value: this.model.password, text: 'Senha <br />'}
        ];
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção',
                `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
            return;
        }
        if (!this.isEMailValid(this.model.email)) {
            return;
        }
        this.loginAdminGQL.mutate({input: this.model}).subscribe(
            (data) => {
                const a: any = data;
                console.log('onLogin');
                this.saveLocalUser(a.data.loginAdmin);
            }, (err) => {
                console.log('onLogin');
            }
        );
    }

    saveLocalUser(data) {
        const token = data as AuthTypeAdmin;
        this.setToken(token.token);
        this.service.updateUser(token.user);
        this.service.getStatusAuthenticated();
        const url = token.user.profile !== 'admin' ? '/participants' : '/';
        this.router.navigate([url]).then();
    }
}
