import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../base/service.base';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {User, UserAdmin} from '../../generated/graphql';

@Injectable({
    providedIn: 'root'
})
export class UserService extends ServiceBase {
    isAuthenticated = false;

    private userDataSource = new BehaviorSubject<UserAdmin>(new UserAdmin());
    user = this.userDataSource.asObservable();

    private notificationDataSource = new BehaviorSubject<Notification[]>([]);
    notification = this.notificationDataSource.asObservable();


    constructor(http: HttpClient) {
        super(http);
    }

    getAuthenticate(model) {
        const url = `${this.BASE_URL}/user/authenticate`;
        return this.http.post(url, model);
    }

    getStatusAuthenticated() {
        const token = localStorage.getItem('token') as string;
        if (token != null) {
            this.isAuthenticated = true;
        } else {
            this.isAuthenticated = false;
        }
        return this.isAuthenticated;
    }

    search(page, filter) {
        const url = `${this.BASE_URL}/user/search/${page}`;
        return this.http.post(url, filter);
    }

    getMe() {
        const url = `${this.BASE_URL}/user/me`;
        return this.http.get(url).subscribe({
            next: data => {
                this.userDataSource.next(data as UserAdmin);
                this.getUserLocal();
            }
        });
    }

    create(model) {
        const url = `${this.BASE_URL}/user`;
        return this.http.post(url, model);
    }

    update(model) {
        const url = `${this.BASE_URL}/user`;
        return this.http.put(url, model);
    }

    updateUser(user: UserAdmin) {
        this.userDataSource.next(user);
    }

    getUserLocal(): UserAdmin {
        return this.userDataSource.value;
    }

    delete(id) {
        const url = `${this.BASE_URL}/user/${id}`;
        return this.http.delete(url);
    }

    getNotification() {
        const url = `${this.BASE_URL}/user/notification`;
        this.http.get(url).subscribe({
            next: data => {
                this.notificationDataSource.next(data as Notification[]);
            }, error: err => {
            }
        });
    }

    updateNotification(id) {
        const url = `${this.BASE_URL}/user/notification/${id}`;
        return this.http.put(url, {});
    }

    getExcelResale(model) {
        const url = `${this.BASE_URL}/users/search-user/excel`;
        return this.http.post(url, model);
    }

    getExcelCoupon(model) {
        const url = `${this.BASE_URL}/users/search-user-coupom/excel`;
        return this.http.post(url, model);
    }

    getExcelWinners(model) {
        const url = `${this.BASE_URL}/winners/search-winner/excel`;
        return this.http.post(url, model);
    }

    getExcelStandart(model) {
        const url = `${this.BASE_URL}/standart/search-standart/excel`;
        return this.http.post(url, model);
    }

}
