<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <h1 class="page-title">Gestão <span>| Banners</span></h1>
            <a class="btn btn-gray-fill" [routerLink]="'/banners/new'">
                <svg-icon class="svg-primary right mr-2" src="assets/svg/plus-circle.svg"></svg-icon>
                Adicionar Banner
            </a>
        </div>

        <div class="table-responsive">
            <table class="table table-striped table_regular">
                <thead>
                <tr>
                    <th>Ordenação</th>
                    <th>Título</th>
                    <th>Perfil</th>
                    <th>Marca</th>
                    <th>Imagem</th>
                    <th>Data de Criação</th>
                    <th>Qtd. de Clic</th>
                    <th>Status</th>
                    <th class="w-10"></th>
                </tr>
                </thead>
                <tbody>
                <tr class="is-cursor" *ngFor="let i of banners">
                    <td>{{i.priority}}</td>
                    <td>{{i.title}}</td>
                    <td>{{i.profile}}</td>
                    <td>{{getBrandName(i.branding)}}</td>
                    <td>
                        <img height="70" [src]="i.url" alt="Imagem"/>
                    </td>
                    <td>{{i.createdAt | date: 'dd/MM/yyyy'}}</td>
                    <td>{{i.clicks}}</td>
                    <td>
                        <span class="badge badge-sm" [ngClass]="{'badge-success' : i.isActive, 'badge-danger' : !i.isActive}">
                            {{i.isActive ? 'Ativo' : 'Inativo'}}
                        </span>
                    </td>
                    <td class="no-wrap">
                        <a href="javascript:void('');" class="px-1" [routerLink]="'/banners/' + i.id">
                            <svg-icon class="mr-2 svg-primary" src="assets/svg/edit.svg"></svg-icon>
                        </a>
                        <a href="javascript:void('');" (click)="actionRemoveBanner(i.id)" class="px-1">
                            <svg-icon class="svg-danger" src="assets/svg/trash.svg"></svg-icon>
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

