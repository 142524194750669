import {BrowserModule} from '@angular/platform-browser';
import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from './views/main/main.component';
import {HeaderComponent} from './views/header/header.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {SidebarComponent} from './views/sidebar/sidebar.component';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxSpinnerModule} from 'ngx-spinner';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {registerLocaleData} from '@angular/common';
import localePt from '@angular/common/locales/pt';
import {LogoutComponent} from './views/logout/logout.component';
import {AppDataService} from './service/app_data.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxPaginationModule} from 'ngx-pagination';
import { HomeComponent } from './views/home/home.component';
import { NotificationComponent } from './views/notification/notification.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { UserComponent } from './views/user/user.component';
import {HeaderHttpInterceptor} from './base/header-interceptor';
// NOVAS VIEWS
import { LoginComponent } from './views/login/login.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import {GraphQLModule} from './graphql.module';
import {CURRENCY_MASK_CONFIG} from 'ng2-currency-mask';
import { ParticipantsComponent } from './views/participants/participants.component';
import { MeasuresComponent } from './views/measures/measures.component';
import { PhotoComponent } from './views/photo/photo.component';
import {LightboxModule} from 'ngx-lightbox';
import {ContactComponent} from './views/contact/contact.component';
import {GoogleChartsModule} from 'angular-google-charts';
import {ResalesComponent} from './views/resales/resales.component';
import {CouponsComponent} from './views/coupons/coupons.component';
import {RafflesComponent} from './views/raffles/raffles.component';
import {BannersComponent} from './views/banners/banners.component';
import {BannersDetailComponent} from './views/banners/banners-detail/banners-detail.component';
import {DocumentationComponent} from './views/documentation/documentation.component';
import {WinnersComponent} from './views/winners/winners.component';
import {CropImageComponent} from './views/crop-image/crop-image.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {NgxMaskModule} from "ngx-mask";
import { ConsultantDetailComponent } from './views/consultant-detail/consultant-detail.component';

registerLocaleData(localePt, 'pt-BR');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    HeaderComponent,
    SidebarComponent,
    LogoutComponent,
    HomeComponent,
    NotificationComponent,
    UserComponent,
    LoginComponent,
    DashboardComponent,
    BannersDetailComponent,
    ParticipantsComponent,
    MeasuresComponent,
    PhotoComponent,
    ContactComponent,
    ResalesComponent,
    CouponsComponent,
    RafflesComponent,
    BannersComponent,
    DocumentationComponent,
    WinnersComponent,
    CropImageComponent,
    ConsultantDetailComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    NgxMaskModule.forRoot(),
    SlickCarouselModule,
    FormsModule,
    GraphQLModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgApexchartsModule,
    LightboxModule,
    NgxPaginationModule,
    GoogleChartsModule,
    ImageCropperModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderHttpInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR'
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL'
    },
    {
      provide: CURRENCY_MASK_CONFIG,
      useValue: {   // BRL
        align: 'left',
        allowNegative: true,
        decimal: ',',
        precision: 2,
        prefix: 'R$ ',
        suffix: '',
        thousands: '.'
      }
    },
    TranslateService,
    AppDataService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
