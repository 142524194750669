<nav id="sidebar">
    <ul class="list-unstyled components navbar-nav" id="accordionExample">

        <li class="header__mobile_ d-none">
            <div class="header">
                <div class="d-flex justify-content-around ">
                    <font class="header_font ">Idioma:</font>
                    <a class="header__link" [ngClass]="{'active' : translate.currentLang === 'pt'}"
                       href="javascript:void('');"
                       (click)="switchLanguage('pt')">PT</a>
                    <span class="separator">|</span>
                    <a class="header__link" [ngClass]="{'active' : translate.currentLang === 'es'}"
                       href="javascript:void('');"
                       (click)="switchLanguage('es')">ES</a>
                </div>
                <div class="header__btn mobile_notification notification_trigger">
                    <app-notification></app-notification>
                </div>
            </div>
        </li>

        <li class="nav-item d-lg-none">
            <a href="javascript:void(0);" [routerLink]="['/']" class="nav-link  sidebar_top"
               style="height: initial;"
               (click)="actionCloseCollapse()">
                <span class="ico"><svg-icon src=""></svg-icon></span>
                <span class="text-center">
                  <!--<span class="lbl sidebar-title">
                      BIO
                  </span>-->
                  <span class="d-block d-lg-none header__user mb-0">
                    <span class="text-primary">{{'headerSalutation' | translate}}, <strong>{{user?.name}}</strong></span>
                  </span>
                </span>
            </a>
        </li>

        <!--<li class="nav-item">
            <a href="javascript:void('Nota');" [routerLink]="['/']" class="nav-link align-items-center"
               style="gap: 20px"
               (click)="actionCloseCollapse()">
                <svg-icon src="assets/svg/home.svg"></svg-icon>
                <span class="lbl">Home</span>
            </a>
        </li>-->

        <ng-container *ngIf="user.profile !== 'admin'">
            <li class="nav-item">
                <a href="javascript:void('Gestão do Programa');" [routerLink]="['/participants']"
                   class="nav-link  align-items-center"
                   style="gap: 20px"
                   (click)="actionCloseCollapse()">
                    <svg-icon src="assets/svg/dashboard.svg"></svg-icon>
                    <span class="lbl">Gestão do Programa</span>
                </a>
            </li>
        </ng-container>

        <ng-container *ngIf="user.profile === 'admin'">
            <li class="nav-item" *ngIf="handlerSideBarNav()">
                <a href="javascript:void('Nota');" [routerLink]="['/dashboard']"
                   class="nav-link  align-items-center"
                   style="gap: 20px"
                   (click)="actionCloseCollapse()">
                    <svg-icon src="assets/svg/dashboard.svg"></svg-icon>
                    <span class="lbl">Dashboard</span>
                </a>
            </li>
            <li class="nav-item">
                <a href="javascript:void('Gestão de Revendas');" [routerLink]="['/resales']"
                   class="nav-link  align-items-center"
                   style="gap: 20px"
                   (click)="actionCloseCollapse()">
                    <svg-icon src="assets/svg/dashboard.svg"></svg-icon>
                    <span class="lbl">Gestão de Revendas</span>
                </a>
            </li>
            <li class="nav-item">
                <a href="javascript:void('Gestão do Programa');" [routerLink]="['/participants']"
                   class="nav-link  align-items-center"
                   style="gap: 20px"
                   (click)="actionCloseCollapse()">
                    <svg-icon src="assets/svg/dashboard.svg"></svg-icon>
                    <span class="lbl">Gestão do Programa</span>
                </a>
            </li>
            <li class="nav-item" *ngIf="handlerSideBarNav()">
                <a href="javascript:void('Gestão de Banners');" [routerLink]="['/banners']"
                   class="nav-link  align-items-center"
                   style="gap: 20px"
                   (click)="actionCloseCollapse()">
                    <svg-icon src="assets/svg/dashboard.svg"></svg-icon>
                    <span class="lbl">Gestão de Banners</span>
                </a>
            </li>
            <li class="nav-item" *ngIf="handlerSideBarNav()">
                <a href="javascript:void('Contato');" [routerLink]="['/contact']" class="nav-link align-items-center"
                   style="gap: 20px"
                   (click)="actionCloseCollapse()">
                    <svg-icon src="assets/svg/message.svg"></svg-icon>
                    <span class="lbl">Contatos</span>
                </a>
            </li>
            <li class="nav-item" *ngIf="handlerSideBarNav()">
                <a href="javascript:void('Usuários Admin');" [routerLink]="['/user']"
                   class="nav-link  align-items-center"
                   style="gap: 20px"
                   (click)="actionCloseCollapse()">
                    <svg-icon src="assets/svg/dashboard.svg"></svg-icon>
                    <span class="lbl">Usuários</span>
                </a>
            </li>
        </ng-container>
    </ul>
    <div class="logout d-lg-none">
        <a href="javascript:void('sair');" [routerLink]="['/login']">
            {{'sair' | translate}}
        </a>
    </div>

</nav>
