<div class="container measures">
    <div class="row">
        <!-- Card de Perguntas-->
        <div class="col-xl-7 col-xxl-8">
            <div class="scroll_primary pt-3 pr-3">
                <div class="config_top">
                    <div class="d-flex">
                        <svg-icon routerLink="/participants" class="is-cursor mr-3"
                                  src="assets/svg/back.svg"></svg-icon>
                        <h2 class="page-header__title large">
                            <strong>Auditoria</strong>
                        </h2>
                    </div>
                    <!--Barra de Recebido-->
                    <div class="received text-capitalize bg-{{standArt.status}}">{{getTranslate(standArt.status)}}</div>
                </div>
                <div class="row justify-content-center mt-4">
                    <div class="col-sm-12 col-lg-9">
                        <div class="register-page_status status-{{standArt.status}}">
                            <div class="d-flex justify-content-between align-items-center position-relative"
                                 *ngIf="standArt?.type === 'adhesion'">
                                <div class="status_item" (click)="actionGetStandart('adhesion')">
                                    <p class="status_text text-status-{{standArt?.status}}">Adesão</p>
                                    <svg-icon class="icon_status"
                                              src="assets/svg/{{standArt?.status}}-status.svg"></svg-icon>
                                    <p class="status_text text-capitalize text-status-{{standArt?.status}} bottom">{{getTranslate(standArt?.status)}}</p>
                                </div>
                                <div class="status_item" (click)="actionGetStandart('standardization')">
                                    <p class="status_text text-status-{{standArt?.sons?.status}}">Padronização</p>
                                    <svg-icon class="icon_status2"
                                              src="assets/svg/{{isNullOrUndefined(standArt?.sons?.status) ? 'standardization.svg' : standArt?.sons?.status + '-status.svg'}}"></svg-icon>
                                    <p class="status_text text-capitalize text-status-{{standArt?.sons?.status}} bottom">
                                        {{getTranslate(standArt?.sons?.status)}}
                                    </p>
                                </div>
                                <div class="status_bar status_standardization">&nbsp;</div>
                            </div>
                            <div class="d-flex justify-content-between align-items-center position-relative"
                                 *ngIf="standArt.type === 'standardization'">
                                <div class="status_item" (click)="actionGetStandart('adhesion')">
                                    <p class="status_text text-status-{{standArt?.father?.status}}">Adesão</p>
                                    <svg-icon class="icon_status"
                                              src="assets/svg/{{standArt?.father?.status}}-status.svg"></svg-icon>
                                    <p class="status_text text-capitalize text-status-{{standArt?.father?.status}} bottom">{{getTranslate(standArt?.father?.status)}}</p>
                                </div>
                                <div class="status_item" (click)="actionGetStandart('standardization')">
                                    <p class="status_text text-status-{{standArt?.status}}">Padronização</p>
                                    <svg-icon class="icon_status2"
                                              src="assets/svg/{{standArt?.status}}-status.svg"></svg-icon>
                                    <p class="status_text text-capitalize text-status-{{standArt?.status}} bottom">
                                        {{getTranslate(standArt?.status)}}
                                    </p>
                                </div>
                                <div class="status_bar status_standardization">&nbsp;</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="config_top mt-3">
                    <div class="d-flex">
                        <h2 class="page-header__title large">
                            <strong class="text-capitalize">{{getTranslate(standArt?.type)}}</strong>
                        </h2>
                    </div>
                </div>
                <div class="top_card">
                    <div class="d-flex flex-column flex-grow-1 my-2">
                        <p>CNPJ</p>
                        <b>{{standArt?.createdBy?.cnpj}}</b>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 my-2">
                        <p>Data de Recebimento</p>
                        <b>{{standArt?.createdAt |date:'dd/MM/yyyy'}}</b>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 my-2">
                        <p>Perfil</p>
                        <b>{{standArt?.createdBy?.resaleType === 'Novo Negócio' ? 'Novo Negócio' : 'Debranding'}}</b>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 my-2">
                        <p>Marca</p>
                        <b>{{standArt?.createdBy?.branding}}</b>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 my-2">
                        <p>Responsável pela Padronização</p>
                        <span class="d-flex justify-content-between">
                            <b>{{getResponsibleStandardization(searchResaleResult.responsibleStandardization)}}</b>
                        </span>
                    </div>
                </div>

                <div class="row my-4">
                    <div class="col-12">
                        <div class="config_top">
                            <div class="d-flex align-items-center flex-wrap">
                                <h2 class="page-header__title large mb-3 pr-4">
                                    <strong>Sua revenda possui frota?</strong>
                                </h2>
                            </div>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" class="custom-control-input" id="customRadio" name="example"
                                   value="customEx"
                                   [disabled]="true"
                                   [checked]="!searchResaleResult.isFleet">
                            <label class="custom-control-label" for="customRadio">Não</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" class="custom-control-input" id="customRadio2" name="example"
                                   value="customEx"
                                   [disabled]="true"
                                   [checked]="searchResaleResult.isFleet"/>
                            <label class="custom-control-label" for="customRadio2">Sim</label>
                        </div>
                    </div>
                </div>

                <hr/>

                <div class="row" *ngIf="documentsProject.length > 0">
                    <div class="col-12">
                        <div class="card card-primary-dark">
                            <div class="row p-3 pb-0">
                                <div class="col-12">
                                    <h4 class="page-header__title mb-1">
                                        <strong>Projeto</strong>
                                    </h4>
                                </div>
                            </div>
                            <div class="row w-100 px-3 mb-2">
                                <div class="col-12 col-md-6">
                                    <h4 class="page-header__title small mb-4">
                                        Status:<br/> <strong
                                            class="text-capitalize">{{documentsProject[documentsProject.length - 1]?.status | translate}}</strong><br/><br/>
                                        Data do Projeto:<br/>
                                        <strong>{{documentsProject[documentsProject.length - 1]?.createdAt | date: 'dd/MM/yyyy'}}</strong><br/><br/>
                                        <span *ngIf="!isNullOrUndefined(documentsProject) && !isNullOrUndefined(documentsProject[documentsProject.length - 1]?.approvedAt)">Data Aprovação:<br/> <strong>{{documentsProject[documentsProject.length - 1]?.approvedAt | date: 'dd/MM/yyyy'}}</strong><br/></span>
                                    </h4>
                                </div>
                                <div class="col-12 col-md-6 text-center">
                                    <a href="{{documentsProject[documentsProject.length - 1]?.url}}" target="_blank"
                                       class="d-flex align-items-center justify-content-center flex-column">
                                        <svg-icon src="assets/svg/file-double.svg" [stretch]="true"
                                                  [svgStyle]="{'width.px': 40, 'height.px': 50}"></svg-icon>
                                        <span class="text-primary">Visualizar</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="config_top mt-5 mb-2">
                    <div class="d-flex">
                        <div class="d-flex align-items-center status-bar {{standArt.frontStatus}}">
                            <div class="status-bar-bg">
                                <svg-icon
                                        src="assets/svg/branches-{{(standArt.frontStatus) === 'received' ? 'analysis' : standArt.frontStatus}}.svg"></svg-icon>
                                <span class="pl-1 text-capitalize">{{(standArt.frontStatus) | translate}}</span>
                            </div>
                        </div>
                        <h2 class="page-header__title large">
                            <strong>Fachada</strong>
                        </h2>
                    </div>
                </div>
                <!--Fotos-->
                <ng-container *ngFor="let f of standArt?.item let i = index">
                    <div class="question_photo">

                        <div class="row align-items-center">
                            <div class="w-100 d-flex justify-content-between flex-wrap">
                                <div class="d-flex flex-grow-1 justify-content-start align-items-center">

                                    <h2 class="page-header__title" *ngIf="f.type !== 'front-wall'">
                                        <span class="page-title ml-3">{{getItemNameByType(f?.type)}}
                                            <small>#{{f?.id.substring(0, 4)}}</small></span>
                                    </h2>
                                    <div class="d-flex align-items-center mx-3" *ngIf="f.type !== 'front-wall'">
                                        <span class="photo-item-text-trash d-flex align-items-center">
                                            <span class="no-wrap mr-2">Placa:</span>
                                            <input type="text" style="width: 100px"
                                                   maxlength="8" [disabled]="standArt.fleetStatus !== 'received'"
                                                   [(ngModel)]="f.plate"
                                                   class="form-control form-control-sm text-primary text-uppercase"/>
                                        </span>
                                    </div>
                                </div>
                                <span class="d-flex flex-grow-1 justify-content-end align-items-center mt-2">
                                    <div class="custom-control custom-switch"
                                         *ngIf="f.type !== 'front-wall' && standArt.type == 'adhesion'">
                                        <input [checked]="f?.brand === 'BR Petrobras'"
                                               [value]="'BR Petrobras'" disabled
                                               (change)="actionChangeBrandItem($event, f)"
                                               type="checkbox" class="custom-control-input"
                                               id="check_force_standardization_{{i}}"
                                               name="check_force_standardization_{{i}}">
                                        <label class="custom-control-label custom-control-label-small"
                                               for="check_force_standardization_{{i}}">Possuí a marca antiga BR Petrobrás?</label>
                                    </div>

                                    <div class="custom-control custom-switch ml-3" *ngIf="standArt.type == 'adhesion'">
                                        <input (change)="actionChangeIsStandardization($event, f)"
                                               [checked]="f?.isStandartized" disabled
                                               type="checkbox" class="custom-control-input"
                                               id="check_force_{{i}}" name="check_force_{{i}}">
                                        <label class="custom-control-label custom-control-label-small"
                                               for="check_force_{{i}}">Padronizada</label>
                                    </div>

                                    <div class="custom-control custom-switch ml-3" *ngIf="f?.type !== 'front-wall'">
                                        <input (change)="actionChangeIsGreen($event, f)"
                                               [checked]="f?.isGreen" disabled
                                               type="checkbox" class="custom-control-input"
                                               id="check_{{i}}" name="check_{{i}}">
                                        <label class="custom-control-label custom-control-label-small"
                                               for="check_{{i}}">Padronização em Verde</label>
                                    </div>

                                </span>
                            </div>
                        </div>

                        <div class="p-3">
                            <div class="photo_row row">
                                <ng-container *ngFor="let p of f.images; let pI = index;">
                                    <div class="photo mb-xl-5 col-xl-6 col-xxl-3">
                                        <img *ngIf="!isNullOrUndefined(p.url)" class="photo_img"
                                             (click)="open(p)" src="{{p.url}}" alt="{{p.name}}">

                                        <div *ngIf="isNullOrUndefined(p.url)" class="photo_img">
                                            <svg id="add_photo_alternate_black_24dp" xmlns="http://www.w3.org/2000/svg"
                                                 width="46" height="46" viewBox="0 0 46 46">
                                                <path id="Caminho_134" data-name="Caminho 134" d="M0,0H46V46H0Z"
                                                      fill="none"/>
                                                <path id="Caminho_135" data-name="Caminho 135"
                                                      d="M37.538,7.75H33.667V3.878A1.881,1.881,0,0,0,31.788,2h-.058a1.886,1.886,0,0,0-1.9,1.878V7.75H25.981a1.91,1.91,0,0,0-1.9,1.878v.058a1.89,1.89,0,0,0,1.9,1.9h3.852v3.853a1.873,1.873,0,0,0,1.9,1.878h.058a1.881,1.881,0,0,0,1.878-1.878V11.583h3.872A1.881,1.881,0,0,0,39.417,9.7V9.628A1.881,1.881,0,0,0,37.538,7.75Zm-9.622,7.686V13.5H25.981a3.8,3.8,0,0,1-3.814-3.872,3.682,3.682,0,0,1,.518-1.878H6.833A3.845,3.845,0,0,0,3,11.583v23a3.845,3.845,0,0,0,3.833,3.833h23a3.845,3.845,0,0,0,3.833-3.833V18.713a3.926,3.926,0,0,1-1.955.537A3.833,3.833,0,0,1,27.917,15.436ZM27.84,34.583H8.75a.958.958,0,0,1-.767-1.533l3.8-5.041a.969.969,0,0,1,1.572.038l3.067,4.619,5-6.67a.954.954,0,0,1,1.514-.019l5.654,7.053A.955.955,0,0,1,27.84,34.583Z"
                                                      transform="translate(2.75 1.833)" fill="#00bb7f"/>
                                            </svg>
                                        </div>
                                        <p class="photo_text">
                                            <span>{{p.name}}</span>
                                        </p>
                                        <ng-container *ngIf="!isNullOrUndefined(p.url)">
                                            <div class="divider"></div>
                                            <ng-container *ngIf="f.type === 'front-wall'">
                                                <div class="icon_2 disabled"
                                                     *ngIf="(getStatusCurrent(searchResaleResult) === 'approved' || getStatusCurrent(searchResaleResult) === 'received') && (f.status === 'received' || f.status === 'approved' || f.status === 'review')">

                                                    <svg-icon [ngClass]="{'approved':p.status ==='approved'}"
                                                              src="assets/svg/accept.svg"></svg-icon>

                                                    <svg-icon [ngClass]="{'review':p.status ==='review'}"
                                                              src="assets/svg/review.svg"></svg-icon>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="f.type !== 'front-wall'">
                                                <div class="icon_2 disabled"
                                                     *ngIf="(getStatusCurrentFleet(searchResaleResult) === 'approved' || getStatusCurrentFleet(searchResaleResult) === 'received') && (f.status === 'received' || f.status === 'approved' || f.status === 'review')">

                                                    <svg-icon [ngClass]="{'approved':p.status ==='approved'}"
                                                              src="assets/svg/accept.svg"></svg-icon>

                                                    <svg-icon [ngClass]="{'review':p.status ==='review'}"
                                                              src="assets/svg/review.svg"></svg-icon>
                                                </div>
                                            </ng-container>
                                        </ng-container>

                                    </div>

                                </ng-container>
                                <div class="pt-3 p-md-3 w-100"
                                     *ngIf="validateJustifyPhoto(f.images) && standArt.status === 'received' && (f.type === 'front-wall' ? getStatusCurrent(searchResaleResult) === 'received' : getStatusCurrentFleet(searchResaleResult) === 'received')">
                                    <div class="input-material input_adesion">
                                        <input id="{{f.id}}" class="form-control js-validate" type="text"
                                               required/>
                                        <label for="{{f.id}}">
                                            <span>Justificativa</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="photo_row row"
                             *ngIf="standArt.type === 'standardization' && f.type === 'front-wall' && (!isNullOrUndefined(documentsAuthorization[documentsAuthorization.length - 1]?.url) || !isNullOrUndefined(standArt.amountMetersPainted) || !isNullOrUndefined(standArt.justification) || !isNullOrUndefined(standArt.justificationVisit))">
                            <div class="col-12">
                                <div class="card card-primary-dark">
                                    <div class="row p-3">
                                        <div class="col-12">
                                            <h4 class="page-header__title mb-4">
                                                <strong>Fornecedor </strong>
                                            </h4>
                                        </div>
                                    </div>

                                    <div class="row w-100 px-3 pb-4 mb-2 text-center">
                                        <div class="col-12">
                                            <div class="card mb-3">
                                                <div class="card-body">
                                                    <div class="d-flex justify-content-between">
                                                        <h4 class="page-header__title small mb-0">
                                                            <strong>Revenda recusou a visita</strong>
                                                        </h4>
                                                        <strong>{{isNullOrUndefined(standArt.justificationVisit) ? 'Não' : 'Sim'}}</strong>
                                                    </div>
                                                    <div class="text-left"
                                                         *ngIf="!isNullOrUndefined(standArt.justificationVisit)">
                                                        <p class="mt-3 mb-1"><strong>Justificativa:</strong></p>
                                                        <p>{{standArt.justificationVisit}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <ng-container *ngIf="!isNullOrUndefined(standArt.justification)">
                                            <div class="col-12">
                                                <div class="card mb-3">
                                                    <div class="card-body">
                                                        <div class="d-flex justify-content-between">
                                                            <h4 class="page-header__title small mb-0">
                                                                <strong>Revenda recusou a padronização</strong>
                                                            </h4>
                                                            <strong>{{isNullOrUndefined(standArt.justification) ? 'Não' : 'Sim'}}</strong>
                                                        </div>
                                                        <div class="text-left"
                                                             *ngIf="!isNullOrUndefined(standArt.justification)">
                                                            <p class="mt-3 mb-1"><strong>Justificativa:</strong></p>
                                                            <p>{{standArt.justification}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>

                                        <ng-container
                                                *ngIf="isNullOrUndefined(standArt.justification) && isNullOrUndefined(standArt.justificationVisit)">
                                            <div class="col-12">
                                                <div class="card mb-3">
                                                    <div class="card-body">
                                                        <div class="d-flex justify-content-between">
                                                            <h4 class="page-header__title small mb-0">
                                                                <strong>Troca de placa e/ou testeira</strong>
                                                            </h4>
                                                            <strong>{{!standArt.isSwapPlate ? 'Não' : 'Sim'}}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 mb-4">
                                                <hr/>
                                            </div>
                                            <div class="col-12 col-md-6"
                                                 *ngIf="!isNullOrUndefined(standArt.amountMetersPainted)">
                                                <h4 class="page-header__title small mb-4">
                                                    <strong>Metragem</strong>
                                                </h4>
                                                <p class="mb-5">Total em m²
                                                    <strong>{{standArt.amountMetersPainted}}</strong></p>
                                                <div class="row">
                                                    <div class="col-10 offset-1">
                                                        <ng-container>
                                                            <div class="divider"></div>
                                                            <div class="icon_2 disabled">

                                                                <svg-icon src="assets/svg/accept.svg"
                                                                          [ngClass]="{'approved': dimensionStatus === 'approved'}"></svg-icon>

                                                                <svg-icon src="assets/svg/review.svg"
                                                                          [ngClass]="{'review': dimensionStatus === 'review'}"></svg-icon>
                                                            </div>
                                                        </ng-container>
                                                        <div class="pt-3 w-100"
                                                             *ngIf="dimensionStatus === 'review' && (getStatusCurrent(searchResaleResult) === 'received')">
                                                            <div class="input-material input_adesion">
                                                                <input id="providerDimension" name="providerDimension"
                                                                       class="form-control js-validate js-validate-dimension"
                                                                       type="text" required/>
                                                                <label for="providerDimension">
                                                                    <span>Justificativa</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6"
                                                 *ngIf="!isNullOrUndefined(documentsAuthorization[documentsAuthorization.length - 1]?.url)">
                                                <h4 class="page-header__title small mb-4">
                                                    <strong>Termo de Autorização</strong>
                                                </h4>
                                                <a href="{{documentsAuthorization[documentsAuthorization.length - 1]?.url}}"
                                                   target="_blank"
                                                   class="d-flex align-items-center justify-content-center flex-column">
                                                    <svg-icon src="assets/svg/file-double.svg" [stretch]="true"
                                                              [svgStyle]="{'width.px': 40, 'height.px': 50}"></svg-icon>
                                                    <span class="text-primary">Visualizar</span>
                                                </a>
                                                <div class="row">
                                                    <div class="col-10 offset-1">
                                                        <ng-container>
                                                            <div class="divider"></div>
                                                            <div class="icon_2 disabled">

                                                                <svg-icon src="assets/svg/accept.svg"
                                                                          [ngClass]="{'approved': documentsAuthorization[documentsAuthorization.length - 1]?.status === 'approved'}"></svg-icon>

                                                                <svg-icon src="assets/svg/review.svg"
                                                                          [ngClass]="{'review': documentsAuthorization[documentsAuthorization.length - 1]?.status === 'review'}"></svg-icon>
                                                            </div>
                                                        </ng-container>
                                                        <div class="pt-3 w-100"
                                                             *ngIf="documentsAuthorization[documentsAuthorization.length - 1]?.status ==='review' && (getStatusCurrent(searchResaleResult) === 'received')">
                                                            <div class="input-material input_adesion">
                                                                <input id="providerAuthorization"
                                                                       name="providerAuthorization"
                                                                       class="form-control js-validate js-validate-authorization"
                                                                       type="text" required/>
                                                                <label for="providerAuthorization">
                                                                    <span>Justificativa</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <ng-container *ngIf="f.type === 'front-wall' && searchResaleResult.isFleet">
                            <div class="d-flex mt-4">
                                <!--<svg-icon class="svg-title" src="assets/svg/photo.svg"></svg-icon>-->
                                <div class="d-flex align-items-center status-bar {{standArt.fleetStatus}}">
                                    <div class="status-bar-bg">
                                        <svg-icon
                                                src="assets/svg/branches-{{(standArt.fleetStatus) === 'received' ? 'analysis' : standArt.fleetStatus}}.svg"></svg-icon>
                                        <span class="pl-1 text-capitalize">{{(standArt.fleetStatus) | translate}}</span>
                                    </div>
                                </div>
                                <h2 class="page-header__title large">
                                    <strong>Frota</strong>
                                </h2>
                            </div>

                            <div class="row w-100 mt-5 mb-2">
                                <div class="col-12">
                                    <h2 class="page-header__title large mb-4 pr-4">
                                        <strong>Termo de Frota</strong>
                                    </h2>
                                </div>
                                <div class="col-12">
                                    <div *ngIf="documents.length === 0">
                                        <p class="text-center py-2">Não enviado</p>
                                    </div>
                                    <div class="row" *ngFor="let x of documents; let i = index;">
                                        <ng-container *ngIf="i === (documents.length - 1)">
                                            <div class="col-12 col-md-6">
                                                <a href="{{x.url}}" target="_blank"
                                                   class="d-flex align-items-center justify-content-center flex-column">
                                                    <svg-icon src="assets/svg/file-double.svg" [stretch]="true"
                                                              [svgStyle]="{'width.px': 40, 'height.px': 50}"></svg-icon>
                                                    <span class="text-primary">Visualizar</span>
                                                </a>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <ng-container>
                                                    <div class="divider"></div>
                                                    <div class="icon_2 disabled">

                                                        <svg-icon src="assets/svg/accept.svg"
                                                                  [ngClass]="{'approved': x.status === 'approved'}"></svg-icon>

                                                        <svg-icon src="assets/svg/review.svg"
                                                                  [ngClass]="{'review': x.status === 'review'}"></svg-icon>
                                                    </div>
                                                </ng-container>

                                                <div class="pt-3 w-100"
                                                     *ngIf="x.status ==='review' && (getStatusCurrentFleet(searchResaleResult) === 'received')">
                                                    <div class="input-material input_adesion">
                                                        <input id="documentJusitfy"
                                                               class="form-control js-validate js-validate-term-fleet"
                                                               type="text" required/>
                                                        <label for="documentJusitfy">
                                                            <span>Justificativa</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>

                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
        <!--Comentarios-->
        <div class="col-xl-5 col-xxl-4">
            <div class="d-flex flex-column justify-content-between pt-3 h-100">
                <div class="comments_card pt-3">
                    <h2 class="page-header__title large">
                        <strong>Comentários</strong>
                    </h2>
                    <div class="scroll_secondary pr-2" style=" overflow-y: auto;">
                        <div class="comment" *ngFor="let m of standartMessages">
                            <div class="top">
                                <p>{{m.createdAt|date:'dd/MM/yyyy'}}</p>
                            </div>
                            <div class="comment_title">{{m.title}}</div>
                            <p>{{m.message}}</p>
                            <div *ngIf="m.reply&&isNullOrUndefined" class="gray_box">
                                Resposta:
                                <b>{{m.reply}}</b>
                            </div>

                        </div>
                    </div>

                    <div class="d-flex flex-grow-1 justify-content-center align-items-center"
                         *ngIf="isNullOrUndefined(standartMessages)">
                        <div>Nenhum comentário registrada</div>
                    </div>
                </div>


                <!--                <a *ngIf="standArt.status === 'received'" (click)="onValidate()"-->
                <!--                   class="btn mt-3 btn-block btn-primary btn-lg text-white">Salvar Avaliação</a>-->


                <!--*ngIf="standArt.status === 'approved' && standArt.type ==='standardization'"-->
                <!--                <a (click)="onRollback()"-->
                <!--                   class="btn mt-3 btn-block btn-danger btn-lg text-white">Resetar</a>-->
            </div>
        </div>
    </div>
</div>