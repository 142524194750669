import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from './views/main/main.component';
import {HomeComponent} from './views/home/home.component';
import {UserComponent} from './views/user/user.component';
import {AuthGuard} from './service/auth-guard.service';
import {LoginComponent} from './views/login/login.component';
import {DashboardComponent} from './views/dashboard/dashboard.component';
import {ParticipantsComponent} from './views/participants/participants.component';
import {MeasuresComponent} from './views/measures/measures.component';
import {PhotoComponent} from './views/photo/photo.component';
import {ContactComponent} from './views/contact/contact.component';
import {ResalesComponent} from './views/resales/resales.component';
import {RafflesComponent} from './views/raffles/raffles.component';
import {CouponsComponent} from './views/coupons/coupons.component';
import {BannersComponent} from './views/banners/banners.component';
import {WinnersComponent} from './views/winners/winners.component';
import {DocumentationComponent} from './views/documentation/documentation.component';
import {BannersDetailComponent} from './views/banners/banners-detail/banners-detail.component';
import {CropImageComponent} from './views/crop-image/crop-image.component';
import {ConsultantDetailComponent} from "./views/consultant-detail/consultant-detail.component";

const routes: Routes = [
    {
        path: 'login', component: LoginComponent
    },
    {
        path: '', component: MainComponent,
        children: [
            {path: 'home', component: HomeComponent},
            {path: 'dashboard', component: DashboardComponent},
            {path: 'contact', component: ContactComponent},
            {path: 'participants', component: ParticipantsComponent},
            {path: 'measures/:id', component: MeasuresComponent},
            {path: 'consultant/:id', component: ConsultantDetailComponent},
            {path: 'photos', component: PhotoComponent},
            {path: 'resales', component: ResalesComponent},
            {path: 'raffles', component: RafflesComponent},
            {path: 'coupons', component: CouponsComponent},
            {path: 'banners', component: BannersComponent},
            {path: 'banners/new', component: BannersDetailComponent},
            {path: 'banners/:id', component: BannersDetailComponent},
            {path: 'winners', component: WinnersComponent},
            {path: 'crop', component: CropImageComponent},
            {path: 'documentation', component: DocumentationComponent},
            {path: 'user', component: UserComponent},
            {path: '', component: DashboardComponent}
        ],
        canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
