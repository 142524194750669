<div class="login">
    <div class="header">
        <img src="assets/images/logo-copaenergia-white.png" alt="copa" style="max-width: 302px"/>
        <div class="desc">
            <p>Programa</p>
            <b>Juntos no gás</b>
        </div>
    </div>

    <div class="content">
        <div class="d-none d-sm-block">
            <img src="assets/images/img-login.png">
        </div>
        <div class="form">
            <b>LOGIN</b>
            <div class="input-material w-80">
                <input id="fieldRE" class="form-control" type="text" [(ngModel)]="model.email" required />
                <label for="fieldRE">
                    <span>E-mail</span>
                </label>
            </div>
            <div class="input-material w-80">
                <input id="senha" class="form-control" type="password"
                       (keyup.enter)="actionLogin()"
                       [(ngModel)]="model.password" required/>
                <label for="senha">
                    <span>Senha</span>
                </label>
            </div>
            <div class="row justify-content-end align-items-end w-80 mt-3 mb-3 d-none">
                <a class="text-white" href="javascript:void('');" (click)="showModal('modalForgot')">Esqueci minha Senha</a>
            </div>
            <button class="btn btn-login  w-80 mt-3" tabindex="0" (click)="actionLogin()">
                <span class="pl-2">Entrar</span>
            </button>

        </div>
    </div>

</div>

<!--modal-->

<div class="modal-custom modal fade" id="modalLogin2" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content modal_content">
            <div class="modal_header d-flex justify-content-between align-items-center">
                <h3 class="header-title">
                    <b>
                        Primeiro acesso
                    </b>
                </h3>
                <a href="javascript:void('');"
                   class="btn"
                   type="button" (click)="closeModal('modalLogin2')">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body modal-body--small" style="min-height: inherit;">
                <div class="d-block w-100">
                    <div class="row mt-5 mb-2">
                        <div class="col-12 row d-flex justify-content-center mb-3">
                            <div class="d-flex justify-content-center col-md-6 col-12">
                            </div>
                            <div class="col-md-6 col-12">
                                <h3 class="text-primary">
                                    <b>Enviamos  para seu  email um código de validação.</b>
                                </h3>
                            </div>
                        </div>
                        <!--NAME-->
                        <div class="d-flex justify-content-around p-5">
                            <!--<div class="input-material input_small">-->
                            <input id="psw1" class="form-control" type="text"
                                   required maxlength="1"/>
                            <!--</div>-->
                            <!--<div class="input-material input_small">-->
                            <input id="psw2" class="form-control" type="text"
                                   required maxlength="1"/>
                            <!--</div>-->
                            <!--<div class="input-material input_small">-->
                            <input id="psw3" class="form-control" type="text"
                                   required maxlength="1"/>
                            <!--</div>-->
                            <!--<div class="input-material input_small">-->
                            <input id="psw4" class="form-control" type="text"
                                   required maxlength="1"/>
                            <!--</div>-->
                            <!--<div class="input-material input_small">-->
                            <input id="psw5" class="form-control" type="text"
                                   required maxlength="1"/>
                            <!--</div>-->
                            <!--<div class="input-material input_small">-->
                            <input id="psw6" class="form-control" type="text"
                                   required maxlength="1"/>
                            <!--</div>-->
                        </div>
                        <div class="row justify-content-center w-100">
                            <a href="javascript:void('');" >Reenviar Código</a>
                        </div>
                        <!--RE-->
                        <div class="col-12 my-3">
                            <button class="btn btn-primary btn-lg text-white w-100 mt-3"
                            ><p class="text-white m-0">Concluir</p></button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal-custom modal fade" id="modalForgot" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content modal_content">
            <div class="modal_header d-flex justify-content-between align-items-center">
                <h3 class="header-title">
                    <b>Esqueci minha senha</b>
                </h3>
                <a href="javascript:void('');"
                   class="btn"
                   type="button" (click)="closeModal('modalForgot')">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body modal-body--small" style="min-height: inherit;">
                <div class="d-block w-100">
                    <div class="row mt-5 mb-2">
                        <div class="col-12 row d-flex justify-content-center mb-3">
                            <div class="d-flex justify-content-center col-md-6 col-12">
                                <img  src="assets/images/photo.png" class="mb-3"  >
                            </div>
                            <div class="col-md-6 col-12">
                                <h3 class="text-primary">
                                    <b>Recupere sua senha informando o e-mail cadastrado.</b>
                                </h3>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="input-material input_small">
                                <input id="forgotPass" class="form-control" type="text"
                                       required maxlength="250"/>
                                <label for="forgotPass">E-mail</label>
                            </div>
                        </div>
                        <div class="col-12 my-3">
                            <button class="btn btn-primary btn-lg text-white w-100 mt-3"
                            ><p class="text-white m-0">Enviar</p></button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>


