import {AfterViewInit, Component} from '@angular/core';
import {BaseComponent} from '../../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {
    Banner,
    CreateBannerGQL, CreateBannerInput,
    GetBannerGQL,
    GetBannerQuery,
    UpdateBannerGQL, UpdateBannerInput
} from '../../../../generated/graphql';
import {QueryRef} from 'apollo-angular';
import {UploadService} from '../../../service/upload.service';


@Component({
    selector: 'app-banner-detail',
    templateUrl: './banners-detail.component.html',
    styleUrls: ['./banners-detail.component.scss']
})
export class BannersDetailComponent extends BaseComponent implements AfterViewInit {

    paramId = '';
    banner: Banner = new Banner();
    bannerQuery: QueryRef<GetBannerQuery>;

    file: File = null;
    bannerBuffer: string | ArrayBuffer;

    arrProfiles: any = [];

    constructor(public router: Router,
                public route: ActivatedRoute,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                public uploadService: UploadService,
                public createBannerGQL: CreateBannerGQL,
                public updateBannerGQL: UpdateBannerGQL,
                public getBannerGQL: GetBannerGQL) {
        super(router, loading, translate);
    }

    ngAfterViewInit(): void {
        this.route.params.subscribe(url => {
            if (!this.isNullOrUndefined(url?.id)) {
                this.paramId = url.id;
                this.bannerQuery = this.getBannerGQL.watch({input: this.paramId});
                this.bannerQuery.valueChanges.subscribe(({data}) => {
                    Object.assign(this.banner, data.banner as Banner);
                    this.bannerBuffer = this.banner.url;
                    this.arrProfiles = this.banner.profile.split('|');
                });
            } else {
                this.banner.isActive = false;
            }
        });
    }

    setCroppedImage(o: any) {
        this.file = o.file[0];
        this.bannerBuffer = o.thumb;
    }

    actionSave() {
        let arrValidateFields = [];

        this.banner.profile = this.arrProfiles.join('|');

        if (this.isNullOrUndefined(this.banner.id)) {
            arrValidateFields = [
                {value: this.banner.isActive, text: 'Status <br>'},
                {value: this.banner.title, text: 'Título <br>'},
                {value: this.banner.profile, text: 'Perfil <br>'},
                {value: this.banner.type, text: 'Tipo <br>'},
                {value: this.banner.branding, text: 'Marca <br>'},
                {value: this.banner.isExternaLink, text: 'Link externo <br>'},
                {value: this.file, text: 'Selecione um arquivo para o banner <br>'}
            ];
        } else {
            arrValidateFields = [
                {value: this.banner.isActive, text: 'Status <br>'},
                {value: this.banner.title, text: 'Título <br>'},
                {value: this.banner.isExternaLink, text: 'Link externo <br>'},
            ];
        }

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Verifique os seguintes campos: <br> ${stringError}`);
            return;
        }

        if (super.isNullOrUndefined(this.banner.id)) {
            this.actionCreate();
        } else {
            this.actionUpdate();
        }
    }

    actionCreate() {
        const data: CreateBannerInput = {
            title: this.banner.title,
            branding: this.banner.branding,
            buttonLink: this.banner.buttonLink,
            type: this.banner.type,
            profile: this.banner.profile,
            // buttonTitle: this.banner.buttonTitle,
            isActive: this.banner.isActive,
            isExternaLink: this.banner.isExternaLink,
            priority: this.banner.priority
        };

        this.createBannerGQL.mutate({input: data}).subscribe((result) => {
            this.banner = result.data.createBanner as Banner;
            this.actionUpload();
        });
    }

    actionUpdate() {
        const data: UpdateBannerInput = {
            id: this.banner.id,
            title: this.banner.title,
            branding: this.banner.branding,
            buttonLink: this.banner.buttonLink,
            type: this.banner.type,
            profile: this.banner.profile,
            // buttonTitle: this.banner.buttonTitle,
            isActive: this.banner.isActive,
            isExternaLink: this.banner.isExternaLink,
            priority: this.banner.priority
        };

        this.updateBannerGQL.mutate({input: data}).subscribe((result) => {
            this.banner = result.data.updateBanner as Banner;
            if (!this.isNullOrUndefined(this.file)) {
                this.actionUpload();
            } else {
                super.showMessage('Sucesso', 'Banner salvo com sucesso', 'success');
                this.router.navigate(['/banners']);
            }
        });
    }

    actionUpload() {
        this.uploadService.upload(this.file, `/upload/banner-image/${this.banner.id}`).subscribe({
            next: data2 => {
                setTimeout(() => {
                    super.showMessage('Sucesso', 'Banner salvo com sucesso', 'success');
                    this.router.navigate(['/banners']);
                }, 300);
            }, error: err => super.onError(err)
        });
    }

    onChangeProfile($event: any) {
        const a = $event.srcElement.value;
        if (this.arrProfiles.includes(a)) {
            this.arrProfiles = this.arrProfiles.filter(x => x !== a);
        } else {
            this.arrProfiles.push(a);
        }
        console.log(this.arrProfiles.join('|'));
    }

    isCheckedProfile(a: string) {
        if (this.isNullOrUndefined(this.arrProfiles)) { return false; }
        return this.arrProfiles.includes(a);
    }
}
