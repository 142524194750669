import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {TranslateService} from "@ngx-translate/core";
import {
    ConsultantStandart,
    CreateStandartMessageGQL,
    CreateStandartMessageInput,
    CreateStandartMessageStandartInput,
    EvalDocumentGQL,
    EvaluateAdhesionGQL,
    EvaluateStandardizationGQL,
    EvaluateStandartInput,
    EvaluateStandartItemImageInput,
    EvaluateStandartItemInput,
    GetServiceProductGQL,
    GetServiceProductQuery,
    GetServiceProductQueryVariables, GetStandartItemServiceByIdGQL,
    LoginAdminGQL,
    RollbackStandartGQL,
    RollbackTeamStandartGQL,
    SearchDocumentsGQL,
    SearchDocumentsQuery,
    SearchResaleGQL,
    SearchResaleQuery, ServiceProductDto,
    Standart,
    StandartGQL,
    StandartItem,
    StandartItemImage, StandartItemServiceDto,
    StandartMessage,
    StandartMessagesGQL,
    StandartQuery,
    StandartQueryVariables, StandartServiceSupplier,
    UpdateBrandStandardItemGQL,
    UpdatePaintedResaleGQL,
    UpdatePlatePendingStandardGQL,
    UpdatePlateStandardItemGQL,
    UpdateResponsibleGQL,
    UpdateTotemAppliedGQL,
    UpdateResaleHasTotemGQL, UpdateResaleTotemLuminousGQL,
    User, UserStandartItem, ReplyStandartMessageInput, ReplyStandartMessageAdminGQL
} from "../../../generated/graphql";
import {QueryRef} from "apollo-angular";
import moment from "moment";
import {Lightbox} from "ngx-lightbox";
import {IAlbum} from "ngx-lightbox/lightbox-event.service";
import {UserService} from "../../service/user.service";

declare var $: any;

@Component({
    selector: 'app-measures',
    templateUrl: './measures.component.html',
    styleUrls: ['./measures.component.scss']
})
export class MeasuresComponent extends BaseComponent implements OnInit, OnDestroy {
    user: User = new User();
    paramId: any;
    standArt: Standart = new Standart();
    standartMessages: StandartMessage[] = [];
    standArtQuery: QueryRef<StandartQuery, StandartQueryVariables>;
    serviceProductsQuery: QueryRef<GetServiceProductQuery, GetServiceProductQueryVariables>;
    // forceApprove = false;

    modelResponsible = '';
    searchResaleQuery: QueryRef<SearchResaleQuery>;
    searchResaleResult: ConsultantStandart = new ConsultantStandart();

    countEmpty = 0;
    countEmptyBrand = 0;
    countFleet = 0;

    documents: any[] = [];
    documentsChecklist: any[] = [];
    documentsChecklistExit: any[] = [];
    documentsFleet: any[] = [];
    documentsAuthorization: any[] = [];
    documentsProject: any[] = [];
    searchDocumentsQuery: QueryRef<SearchDocumentsQuery>;
    searchDocumentsFleetQuery: QueryRef<SearchDocumentsQuery>;
    searchDocumentsAuthorizationQuery: QueryRef<SearchDocumentsQuery>;
    searchDocumentsProjectQuery: QueryRef<SearchDocumentsQuery>;

    dimensionStatus = null;

    modalVehicleTab = 0;
    itemService: StandartServiceSupplier = new StandartServiceSupplier();
    itemServiceList: ServiceProductDto[] = [];

    textResponse: string;
    modalMessage: StandartMessage = new StandartMessage();

    constructor(public router: Router,
                public route2: ActivatedRoute,
                public loading: NgxSpinnerService,
                public standArtByIdGQL: StandartGQL,
                public evaluateAdhesionGQL: EvaluateAdhesionGQL,
                public evaluateStandardizationGQL: EvaluateStandardizationGQL,
                public getStandartItemServiceByIdGQL: GetStandartItemServiceByIdGQL,
                public standartMessagesGQL: StandartMessagesGQL,
                public createStandartMessageGQL: CreateStandartMessageGQL,
                private searchResaleGQL: SearchResaleGQL,
                private updateResponsibleGQL: UpdateResponsibleGQL,
                private rollbackStandartGQL: RollbackStandartGQL,
                private updatePlateStandardItemGQL: UpdatePlateStandardItemGQL,
                private searchDocumentsGQL: SearchDocumentsGQL,
                private evalDocumentGQL: EvalDocumentGQL,
                private updateBrandStandardItemGQL: UpdateBrandStandardItemGQL,
                private updatePlatePendingStandardGQL: UpdatePlatePendingStandardGQL,
                public userService: UserService,
                private lightbox: Lightbox,
                public translate: TranslateService,
                private rollbackTeamStandartGQL: RollbackTeamStandartGQL,
                private updatePaintedResale: UpdatePaintedResaleGQL,
                private updateTotemAppliedGQL: UpdateTotemAppliedGQL,
                private getServiceProductGQL: GetServiceProductGQL,
                private updateResaleHasTotemGQL: UpdateResaleHasTotemGQL,
                private replyStandartMessageAdminGQL: ReplyStandartMessageAdminGQL,
                private updateResaleTotemLuminousGQL: UpdateResaleTotemLuminousGQL,
    ) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data?.id)) {
                    this.user = data as User;
                    if (this.user.profile !== 'admin') {
                        this.router.navigate(['/participants']);
                    }
                }
            }, error: err => super.onError(err)
        });
        this.route2.params.subscribe(url => {
            console.log('ROUTE');
            if (!this.isNullOrUndefined(url)) {
                this.paramId = url.id;
                this.loading.show();
                this.standArtQuery = this.standArtByIdGQL.watch({id: this.paramId});
                this.standArtQuery.valueChanges.subscribe(({data}) => {
                    this.standArt = JSON.parse(JSON.stringify(data.standart));
                    if (!this.isNullOrUndefined(this.standArt.metersStatus)) {
                        this.dimensionStatus = this.standArt.metersStatus;
                    }
                    this.countFleet = this.standArt.item.filter(x => x.type !== 'front-wall').length;
                    this.searchResaleQuery = this.searchResaleGQL.watch({input: this.standArt.createdBy.id});
                    this.searchResale();
                    // this.modelResponsible = this.standArt.r
                    this.getListMessage();
                    this.loading.hide();
                }, error => super.onError(error));
            }
        });
    }

    ngOnDestroy(): void {
        const el = $('#responseFleet');
        if (el[0]) {
            el.remove();
        }
    }

    getListMessage() {
        // this.loading.show();
        this.standartMessagesGQL.watch({id: this.paramId}).valueChanges.subscribe(({data}) => {
            this.standartMessages = JSON.parse(JSON.stringify(data.standartMessages));
            // this.loading.hide();
        }, error => super.onError(error));
    }

    searchResale() {
        this.searchResaleQuery.valueChanges.subscribe(({data}) => {
            this.searchResaleResult = data.searchResale as ConsultantStandart;
            this.searchDocument();
            this.searchDocumentAuthorization();
            this.searchDocumentProject();
            //if (this.standArt.isStandardizationPerformed !== null && this.standArt.isStandardizationPerformed !== undefined) {
                this.searchDocumentFleet();
            //}
            if (this.isNullOrUndefined(this.searchResaleResult?.type)) {
                this.showModal('responseFleet');
                return;
            }

        });
    }

    onRollback() {
        this.confirmMessage('Atenção',
            'Você vai resetar essa padronização, essa ação não pode ser desfeita. Tem certeza que deseja continuar?',
            () => {
                this.rollbackStandartGQL.mutate({id: this.standArt.createdBy.id}).subscribe((data) => {
                    this.router.navigate(['/participants']);
                    // console.log(data.data.rollbackStandart);
                });
            });
    }

    onRollbackTeam() {
        this.confirmMessage('Atenção',
            'Você vai desvincular a equipe dessa padronização. Tem certeza que deseja continuar?',
            () => {
                this.rollbackTeamStandartGQL.mutate({id: this.standArt.createdBy.id}).subscribe((data) => {
                    this.router.navigate(['/participants']);
                });
            });
    }

    getStatusCurrentFleetStandart(x: any) {
        return this.standArt.type === 'adhesion' ? x.fleetStatusAdhesion : x.fleetStatusStandardization;
    }

    onValidate() {
        this.countEmpty = 0;
        this.loading.show();
        const input: EvaluateStandartInput = {
            id: this.paramId,
            item: this.createListItem(),
            documentId: null,
            documentStatus: null,
            metersStatus: null,
            authorizationId: null,
            authorizationStatus: null,
        };

        /*if (this.countEmptyBrand > 0) {
            this.loading.hide();
            this.showMessage('Atenção!', 'Para prosseguir com veículos padronizados, selecione a marca que foi adesivada.', 'warning');
            return;
        }*/
        if (this.countEmpty > 0) {
            this.loading.hide();
            this.showMessage('Atenção!', 'Para prosseguir é necessário avaliar todos os itens.', 'warning');
            return;
        }
        if (this.searchResaleResult.isFleet && this.documents.length > 0 && this.getStatusCurrentFleet(this.searchResaleResult) === 'received' && (this.documents[this.documents.length - 1].status === 'pending' || this.documents[this.documents.length - 1].status === 'received')) {
            this.showMessage('Atenção!', 'Avalie o termo de frota para prosseguir.', 'warning');
            this.loading.hide();
            return;
        }
        if (this.isNullOrUndefined(this.standArt.justification) && this.getStatusCurrent(this.searchResaleResult) === 'received' && this.standArt.type === 'standardization' && (this.isNullOrUndefined(this.dimensionStatus) || this.dimensionStatus === 'pending')) {
            this.showMessage('Atenção!', 'Avalie a metragem do fornecedor para prosseguir.', 'warning');
            this.loading.hide();
            return;
        }

        if (this.isNullOrUndefined(this.standArt.justification) && this.getStatusCurrent(this.searchResaleResult) === 'received' && this.standArt.type === 'standardization' && (this.documentsAuthorization[this.documentsAuthorization.length - 1]?.status === 'pending')) {
            this.showMessage('Atenção!', 'Avalie o termo de autorização do fornecedor para prosseguir.', 'warning');
            this.loading.hide();
            return;
        }

        if (this.validateCreateMessage()) {
            this.showMessage('Atenção!', 'Preencha os campos de justificativa!', 'warning');
            this.loading.hide();
            return;
        }
        /*if () {
            this.loading.hide();
            return;
        }*/
        if ((this.documents.length > 0 && this.documents[this.documents.length - 1].currentStatus === 'approved') || (this.documents.length > 0 && this.documents[this.documents.length - 1].currentStatus !== 'approved' && this.getStatusCurrentFleetStandart(this.searchResaleResult) === 'received')) {
            const doc = this.documents[this.documents.length - 1];
            input.documentId = doc.id;
            input.documentStatus = doc.status;
        }

        // tslint:disable-next-line:max-line-length
        if (this.standArt.type === 'standardization' && !this.isNullOrUndefined(this.dimensionStatus) && (this.standArt.metersStatus !== 'approved')) {
            input.metersStatus = this.dimensionStatus;
        }
        if (this.standArt.type === 'standardization' && !this.isNullOrUndefined(this.documentsAuthorization) && (this.documentsAuthorization[this.documentsAuthorization.length - 1]?.currentStatus !== 'approved')) {
            const docAuthorization = this.documentsAuthorization[this.documentsAuthorization.length - 1];
            input.authorizationId = docAuthorization?.id;
            input.authorizationStatus = docAuthorization?.status;
        }

        if (this.standArt.type === 'adhesion') {
            this.onTransmitAdhesion(input);
        } else {
            this.onTransmitStandardization(input);
        }
    }

    onTransmitAdhesion(o: EvaluateStandartInput): void {
        this.evaluateAdhesionGQL.mutate({input: o}).subscribe(({data}) => {
            const result: any = data;
            if (!result.errors) {
                this.createMessage();
            }
            setTimeout(() => {
                this.standArtQuery.refetch({id: this.paramId});
                this.getListMessage();
                this.loading.hide();
                this.showMessage('Sucesso!', 'Avaliação enviada com sucesso!', 'success');
            }, 500);
        }, error => super.onError(error));
    }

    onTransmitStandardization(o: EvaluateStandartInput): void {
        this.evaluateStandardizationGQL.mutate({input: o}).subscribe(({data}) => {
            const result: any = data;
            if (!result.errors) {
                this.createMessage();
            }
            setTimeout(() => {
                this.standArtQuery.refetch({id: this.paramId});
                this.getListMessage();
                this.loading.hide();
                this.showMessage('Sucesso!', 'Avaliação enviada com sucesso!', 'success');
            }, 500);
        }, error => super.onError(error));
    }

    open(p: StandartItemImage): void {
        const iAlbum: IAlbum = {
            caption: p.name,
            src: p.url,
            thumb: p.url,
        };
        this.lightbox.open([iAlbum], 0, {
            wrapAround: false,
            showImageNumberLabel: false,
            disableScrolling: true,
            showZoom: false,
            showRotate: false
        });
    }

    validateCreateMessage() {
        let count = 0;
        const el = $('.js-validate');
        if (el[0]) {
            el.each((i, e) => {
                if (super.isNullOrUndefined($(e).val())) {
                    count++;
                }
            });
        }
        return count > 0;
    }

    createMessage() {
        const standArt: CreateStandartMessageStandartInput = {
            id: this.paramId,
        };
        const input: CreateStandartMessageInput[] = [];
        /*this.standArt.walls.forEach((e) => {
            if (e.status === 'review' || e.status === 'reproved') {
                return input.push({title: 'Medidas', type: e.type, message: $('#' + e.id).val(), standart: standArt});
            }
        });
        this.standArt.complement.forEach((e) => {
            if (e.status === 'review' || e.status === 'reproved') {
                return input.push({title: 'Medidas', type: e.type, message: $('#' + e.id).val(), standart: standArt});
            }
        });*/
        this.standArt.item.forEach((e) => {

            let message = $('#' + e.id).val();
            if(e.type == "projects") {
                message = $('#project_' + e.id).val();
            }

            if (this.isNullOrUndefined(message)) {
                return;
            }
            if (this.validateJustifyPhoto(e.images)) {
                return input.push({
                    title: this.getItemNameByType(e.type) + ' #' + e.id.substring(0, 4),
                    type: e.type,
                    message: message,
                    standart: standArt
                });
            }
        });

        if (this.documents.length > 0 && this.documents[this.documents.length - 1].status === 'review' && this.getStatusCurrentFleet(this.searchResaleResult) === 'received') {
            input.push({
                title: this.getItemNameByType('document'),
                type: 'document',
                message: $('.js-validate-term-fleet').val(),
                standart: standArt
            });
        }

        if (this.standArt.type === 'standardization' && !this.isNullOrUndefined(this.documentsAuthorization) && (this.documentsAuthorization[this.documentsAuthorization.length - 1]?.status === 'review')) {
            input.push({
                title: this.getItemNameByType('documentAuthorization'),
                type: 'documentAuthorization',
                message: $('.js-validate-authorization').val(),
                standart: standArt
            });
        }

        if (this.standArt.type === 'standardization' && !this.isNullOrUndefined(this.dimensionStatus) && (this.dimensionStatus === 'review')) {
            input.push({
                title: this.getItemNameByType('dimension'),
                type: 'dimension',
                message: $('.js-validate-dimension').val(),
                standart: standArt
            });
        }
        // if (this.isNullOrUndefined(input) && !this.forceApprove) {
        //     this.standArtQuery.refetch({id: this.paramId});
        //     return;
        // }

        this.createStandartMessageGQL.mutate({input: input}).subscribe(({data}) => {
            // setTimeout(() => {
            // this.standArtQuery.refetch({id: this.paramId});
            // }, this.forceApprove ? 2000 : 0);
            this.getListMessage();
        }, error => super.onError(error));
    }

    handlerStatusItem(i: number, pI: number, status: string, itemFather?) {
        if (!this.isNullOrUndefined(itemFather) && (itemFather.currentStatus === 'approved' || itemFather.currentStatus === 'reproved')) {
            return;
        }
        if (this.standArt.item[i].images[pI].currentStatus === 'approved' || this.standArt.item[i].images[pI].currentStatus === 'reproved') {
            return;
        }
        if (this.standArt.status === 'review') {
            return;
        }
        this.standArt.item[i].images[pI].status = status;
    }

    validateJustifyPhoto(images: StandartItemImage[]) {
        return images.some(element => element.status === 'reproved' || element.status === 'review');
    }

    createListItem() {
        this.countEmptyBrand = 0;
        const itemInput: EvaluateStandartItemInput[] = [];
        this.standArt.item.forEach((e) => {
            /*if (e.isStandartized && this.isNullOrUndefined(e.brand)) {
                this.countEmptyBrand++;
            }*/
            if (e.status === 'received') {
                itemInput.push({
                    id: e.id,
                    type: e.type,
                    plate: e.plate?.trim(),
                    isGreen: e.isGreen,
                    brand: e.brand,
                    isStandartized: e.isStandartized,
                    images: this.createImage(e.images),
                });
            }
        });
        return itemInput;
    }

    createImage(images: StandartItemImage[]) {
        const imageInput: EvaluateStandartItemImageInput[] = [];
        images.forEach((e) => {
            console.log('e.status');
            console.log(e.status);
            if (e.status === 'received')
                this.countEmpty++;

            if (e.status != 'received' && e.status != 'pending')
                imageInput.push({id: e.id, status: e.status});
        });
        return imageInput;
    }

    actionGetStandart(t: string) {
        let id = null;
        if (t === this.standArt.type) {
            return;
        }
        if (!this.isNullOrUndefined(this.standArt.father)) {
            id = this.standArt.father.id;
        } else if (!this.isNullOrUndefined(this.standArt.sons)) {
            id = this.standArt.sons.id;
        }
        if (this.isNullOrUndefined(id)) {
            return;
        }
        window.location.href = '/measures/' + id;
    }

    actionChangeIsStandardization(c: any, el) {
        el.isStandartized = c.srcElement.checked;
    }

    actionChangeIsGreen(c: any, el) {
        el.isGreen = c.srcElement.checked;
    }

    onUpdateResponsible() {
        if (this.isNullOrUndefined(this.modelResponsible)) {
            this.showMessage('Atenção!', 'Selecione um responsável para prosseguir.', 'warning');
            return;
        }
        super.confirmMessage('Atenção', 'Tem certeza que deseja alterar o responsável pela padronização?', () => {
            this.setUpdateResponsible();
        });
    }

    setUpdateResponsible() {
        this.updateResponsibleGQL.mutate({
            respons: this.modelResponsible,
            input: this.searchResaleResult?.id
        }).subscribe(
            (data) => {
                this.closeModal('responseFleet');
                this.standArtQuery.refetch({id: this.paramId});
            }, err => super.onError(err)
        );
    }

    searchDocument() {
        this.searchDocumentsQuery = this.searchDocumentsGQL.watch({
            id: this.searchResaleResult.id,
            type: 'fleet-authorization'
        });
        this.searchDocumentsQuery.valueChanges.subscribe(({data}) => {
            this.documents = data.searchDocuments;
            this.documents.map(x => x.currentStatus = x.status);
        }, error => super.onError(error));
    }

    searchDocumentFleet() {
        this.searchDocumentsFleetQuery = this.searchDocumentsGQL.watch({
            id: this.searchResaleResult.id,
            type: 'certificate'
        });
        this.searchDocumentsFleetQuery.valueChanges.subscribe(({data}) => {
            this.documentsFleet = data.searchDocuments;
            this.documentsFleet.map(x => x.currentStatus = x.status);
        }, error => super.onError(error));
    }

    searchDocumentAuthorization() {
        this.searchDocumentsAuthorizationQuery = this.searchDocumentsGQL.watch({
            id: this.searchResaleResult.id,
            type: 'authorization'
        });
        this.searchDocumentsAuthorizationQuery.valueChanges.subscribe(({data}) => {
            this.documentsAuthorization = data.searchDocuments;
            this.documentsAuthorization.map(x => x.currentStatus = x.status);
        }, error => super.onError(error));
    }

    searchDocumentProject() {
        this.searchDocumentsProjectQuery = this.searchDocumentsGQL.watch({
            id: this.searchResaleResult.id,
            type: 'project'
        });
        this.searchDocumentsProjectQuery.valueChanges.subscribe(({data}) => {
            this.documentsProject = data.searchDocuments;
        }, error => super.onError(error));
    }

    onUpdatePlate(f: StandartItem) {
        console.log('onUpdatePlate');
        console.log(f);
        this.updatePlateStandardItemGQL.mutate({respons: f.plate, input: f.id}).subscribe((data) => {
            // this.router.navigate(['/participants']);
            // console.log(data.data.rollbackStandart);
        });
    }

    handlerStatusDocument(x: any, s: string) {
        if (!this.isNullOrUndefined(x) && ((this.getStatusCurrentFleet(this.searchResaleResult) !== 'received' && !this.standArt.createdBy.isCreatedStandardizationFromAdhesion) || x.currentStatus === 'approved')) {
            return;
        }
        x.status = s;
    }

    handlerStatusDocumentAuthorization(x: any, s: string) {
        if (!this.isNullOrUndefined(x) && this.getStatusCurrent(this.searchResaleResult) !== 'received' || x.currentStatus === 'approved') {
            return;
        }
        x.status = s;
    }

    actionChangeBrandItem(c: any, f: StandartItem) {
        f.brand = c.srcElement.checked === true ? 'BR Petrobras' : '';
        this.updateBrandStandardItemGQL.mutate({respons: f.brand, input: f.id}).subscribe((data) => {
            // console.log(data);
        });
    }

    handlerStatusDimension(x: any, s: string) {
        if (!this.isNullOrUndefined(x) && this.getStatusCurrent(this.searchResaleResult) !== 'received' || x === 'approved') {
            return;
        }
        this.dimensionStatus = s;
    }

    setPlatePending(b: any) {
        this.confirmMessage('Atenção', 'Tem certeza que deseja alterar o status da placa?', () => {
            console.log(this.standArt.isPlatePending);
            this.updatePlatePendingStandardGQL.mutate({
                data: this.standArt.isPlatePending,
                id: this.standArt.id
            }).subscribe(({data}) => {
                this.showMessage('Sucesso!', 'O status da placa foi alterado com sucesso.', 'success');
            }, error => super.onError(error));
        }, () => {
            this.standArt.isPlatePending = !this.standArt.isPlatePending;
        });
    }

    setResalePaint(b?:boolean) {
        this.confirmMessage('Atenção', 'Tem certeza que deseja prosseguir?', () => {
            this.standArt.isPaintedResale = b;
            this.updatePaintedResale.mutate({
                data: this.standArt.isPaintedResale,
                id: this.standArt.id
            }).subscribe(({data}) => {
                this.showMessage('Sucesso!', 'Alteração realizada com sucesso.', 'success');
            }, error => super.onError(error));
        });
    }

    setTotemAdd(b: boolean) {
        this.confirmMessage('Atenção', 'Tem certeza que deseja prosseguir?', () => {
            this.updateTotemAppliedGQL.mutate({
                data: this.standArt.isTotemApplied,
                id: this.standArt.id
            }).subscribe(({data}) => {
                this.showMessage('Sucesso!', 'Alteração realizada com sucesso.', 'success');
            }, error => super.onError(error));
        });
    }

    addService(model: StandartItem) {
        // console.log('addService');
        this.modalVehicleTab = 0;
        this.itemService = new StandartServiceSupplier();
        this.itemService = model.services[0];
        this.documentsChecklist = this.itemService.documents?.filter(x => x.type === 'checklist');
        this.documentsChecklistExit = this.itemService.documents?.filter(x => x.type === 'checklistExit');
        this.serviceProductsQuery = this.getServiceProductGQL.watch({id: this.itemService.id});
        this.serviceProductsQuery.valueChanges.subscribe(({data}) => {
            this.showModal('modalVehicleChecklist');
            this.itemServiceList = data.getServiceProduct as ServiceProductDto[];
            // console.log(this.itemServiceList);
        }, error => super.onError(error));
    }

    setTotem(b: boolean) {
        this.updateResaleHasTotemGQL.mutate({input: this.standArt.isHasTotem, data: this.standArt.id}).subscribe(
            (data) => {
                // console.log('alterado com sucesso!');
            }, err => super.onError(err)
        );
    }

    setTotemLuminous(value: boolean) {
        // console.log('setTotemLuminous');
        // console.log(value);
        this.updateResaleTotemLuminousGQL.mutate({input: this.standArt.isTotemLuminous, data: this.standArt.id}).subscribe(
            (data) => {
                console.log('alterado com sucesso!');
            }, err => super.onError(err)
        );
    }

    handlerResponse(m: StandartMessage) {
        this.modalMessage = Object.assign({}, m);
        this.textResponse = undefined;
        super.showModal('modalComments');
    }

    actionResponse() {
        const arrValidateFields = [
            {value: this.textResponse, text: `Mensagem <br>`}
        ];
        const stringError = this.validateFieldZero(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção',
                `Preencha os campos obrigatórios corretamente:<br/><br/> ${stringError}`);
            return;
        }
        const modelCreate: ReplyStandartMessageInput = {
            id: this.modalMessage.id,
            reply: this.textResponse,
        };
        this.loading.show();
        this.replyStandartMessageAdminGQL.mutate({input: modelCreate}).subscribe(({data}) => {
            super.closeModal('modalComments');
            this.getListMessage();
            this.loading.hide();
            this.showMessage('Sucesso!', 'Resposta enviada com sucesso!', 'success');
        }, error => super.onError(error));
    }
}
