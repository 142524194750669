<div class="body">
    <div class="header border-0 mt-3 ml-3">
        <div class="d-flex">
            <a href="javascript:history.back(-1);">
                <svg-icon class="mr-5 is-cursor" src="assets/svg/back.svg"></svg-icon>
            </a>
            <h1 class="header-title">
                Novo Banner
            </h1>
        </div>
    </div>
    <div class="container banner_container mt-4">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="d-flex flex-column">
                    <div class="d-flex">
                        <div class="card_beige beige-header d-flex justify-content-between align-items-center text-primary">
                            <b>Status {{banner.isActive ? 'Ativo' : 'Inativo'}}</b>
                            <div class="custom-control custom-switch">
                                <input [(ngModel)]="banner.isActive" [checked]="banner.isActive === true"
                                       type="checkbox" class="custom-control-input"
                                       id="check_b" name="check_b"
                                >
                                <label class="custom-control-label"
                                       for="check_b"></label>
                            </div>
                            <!--<label class="switch  cursor-default">
                                <input [(ngModel)]="banner.isActive" type="checkbox" name="checkbox">
                                <span class="slider round cursor-default">
                            </span>
                            </label>-->
                        </div>
                    </div>
                    <fieldset class="row mt-4">
                        <div class="col-12 mb-3">
                            <label>Perfil</label>
                            <div class="d-flex flex-wrap">
                                <div class="custom-control custom-switch mx-3 mb-3">
                                    <input type="checkbox" class="custom-control-input" (change)="onChangeProfile($event)"
                                           [checked]="isCheckedProfile('RV')"
                                           id="check_profile_b_0" name="check_profile_b_0" [value]="'RV'" />
                                    <label class="custom-control-label" for="check_profile_b_0">Revenda</label>
                                </div>
                                <div class="custom-control custom-switch mx-3 mb-3">
                                    <input type="checkbox" class="custom-control-input" (change)="onChangeProfile($event)"
                                           [checked]="isCheckedProfile('EC')"
                                           id="check_profile_b_1" name="check_profile_b_1" [value]="'EC'" />
                                    <label class="custom-control-label" for="check_profile_b_1">Comercial CCE</label>
                                </div>
                                <div class="custom-control custom-switch mx-3 mb-3">
                                    <input type="checkbox" class="custom-control-input" (change)="onChangeProfile($event)"
                                           [checked]="isCheckedProfile('GVE')"
                                           id="check_profile_b_2" name="check_profile_b_2" [value]="'GVE'" />
                                    <label class="custom-control-label" for="check_profile_b_2">Comercial GVE</label>
                                </div>
                                <div class="custom-control custom-switch mx-3 mb-3">
                                    <input type="checkbox" class="custom-control-input"
                                           [checked]="true"
                                           [disabled]="true"
                                           id="check_profile_b_3" name="check_profile_b_3" value="MA" />
                                    <label class="custom-control-label" for="check_profile_b_3">Master</label>
                                </div>
                            </div>
                            <!--<div class="input-material">
                                <select [(ngModel)]="banner.profile" name="bannerTextBtn"
                                        class="form-control" required>
                                    <option [ngValue]="null">Todos</option>
                                    <option value="RV">Revenda</option>
                                    <option value="EC">Comercial CCE</option>
                                    <option value="GVE">Comercial GVE</option>
                                    <option value="MA">Master</option>
                                </select>
                                <label for="profileTextBtn">Perfil</label>
                            </div>-->
                        </div>
                        <div class="col-12">
                            <div class="input-material">
                                <input [(ngModel)]="banner.title" name="bannerTitle" id="bannerTitle"
                                       class="form-control"
                                       type="text"
                                       required maxlength="150"/>
                                <label for="bannerTitle">Título</label>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="input-material">
                                <select [(ngModel)]="banner.type" name="bannerTextBtn" id="profileTextBtn"
                                        class="form-control" required>
                                    <option [ngValue]="null">Todos</option>
                                    <option value="Debranding">Debranding</option>
                                    <option value="Novo Negócio">Novo Negócio</option>
                                </select>
                                <label for="profileTextBtn">Tipo</label>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="input-material">
                                <select [(ngModel)]="banner.branding" name="bannerTextBtn" id="brandingTextBtn"
                                        class="form-control"
                                        required>
                                    <option value="liquigas">Liquigás</option>
                                    <option value="copagaz">Copagaz</option>
                                </select>
                                <label for="brandingTextBtn">Marca</label>
                            </div>
                        </div>
                        <!--                        <div class="col-12">-->
                        <!--                            <div class="btnlink text-primary">-->
                        <!--                                <b>Botão com Link</b>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                        <div class="col-12">
                            <div class="input-material">
                                <input [(ngModel)]="banner.buttonLink" name="bannerLink" id="bannerLink"
                                       class="form-control"
                                       type="text"
                                       required maxlength="250"/>
                                <label for="bannerTitle">Link</label>
                            </div>
                        </div>
                        <!--                        <div class="col-12">-->
                        <!--                            <div class="input-material">-->
                        <!--                                <input [(ngModel)]="banner.buttonTitle" name="bannerTextBtn" id="bannerTextBtn"-->
                        <!--                                       class="form-control"-->
                        <!--                                       type="text"-->
                        <!--                                       required maxlength="150"/>-->
                        <!--                                <label for="bannerTitle">Texto do botão</label>-->
                        <!--                            </div>-->
                        <!--                        </div>-->

                        <div class="col-12">
                            <div class="input-material">
                                <input [(ngModel)]="banner.priority" name="bannerOrder" id="bannerOrder"
                                       class="form-control"
                                       type="number" required maxlength="2"/>
                                <label for="bannerTitle">Ordenação</label>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class=" select_row mt-3 text-primary">
                                <b>Link Externo</b>
                                <div class="form-check text-primary">
                                    <label class="form-check-label" for="flexRadioDefault1">
                                        Sim
                                    </label>
                                    <input [(ngModel)]="banner.isExternaLink" class="form-check-input select_radio"
                                           type="radio" name="flexRadioDefault"
                                           [value]="true"
                                           id="flexRadioDefault1">

                                </div>
                                <div class="form-check select_btn">
                                    <label class="form-check-label" for="flexRadioDefault2" style="color: darkgreen">
                                        Não
                                    </label>
                                    <input [(ngModel)]="banner.isExternaLink" class="form-check-input select_radio"
                                           type="radio" name="flexRadioDefault"
                                           [value]="false"
                                           id="flexRadioDefault2">

                                </div>
                            </div>
                        </div>


                    </fieldset>
                </div>


            </div>
            <div class="col-12 col-md-6">
                <div class="card_beige">
                    <div class="card_beige_header text-primary">
                        <b>Imagem</b>
                    </div>
                    <div class="card_beige_content is-cursor" (click)="showModal('modalCropBanner')">
                        <div class="d-flex flex-column justify-content-center align-items-center">
                            <svg-icon src="assets/svg/image.svg"></svg-icon>
                            <p>Faça o upload do arquivo</p>
                            <img src="{{bannerBuffer}}" alt="">
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <div class="row mt-5">
            <div class="col-6">
                <div class="col-12 p-0">
                    <div class="d-flex justify-content-start col-12  p-0 mb-3">
                        <a [routerLink]="['/banners']" href="javascript:void(0)"
                           class="btn btn-outline-primary  col-12 col-md-7  mt-3"
                           type="button">
                            <span class="ml-1">Cancelar</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="d-flex justify-content-center col-12  p-0 mb-3">
                    <a (click)="actionSave()" href="javascript:void(0)"
                       class="btn btn-primary  col-12 col-md-7  mt-3"
                       type="button">
                        <span class="ml-1" style="color: white">Publicar</span>
                    </a>
                </div>
            </div>
        </div>

    </div>
</div>


<app-crop-image [cropId]="'modalCropBanner'"
                [cropAspectRatio]="15/6"
                [cropResizeToWidth]="1600"
                [cropResizeToHeight]="800"
                [size]="20000000"
                (croppedImageResult)="setCroppedImage($event)"></app-crop-image>
