import {AfterViewInit, Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';
import {CurrentUserAdminGQL, CurrentUserGQL} from '../../../generated/graphql';

declare var $: any;

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent extends BaseComponent implements OnInit, AfterViewInit {

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                private currentUserAdminGQL: CurrentUserAdminGQL,
                public userService: UserService) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        moment.locale('pt-br');
        // console.log('aqui main');
        // console.log(this.userService.getStatusAuthenticated());
        if (this.userService.getStatusAuthenticated()) {
            this.getCurrentUser();
        } else {
            localStorage.clear();
            this.userService.isAuthenticated = false;
            this.router.navigate(['/login']);
        }
    }

    ngAfterViewInit(): void {
        $(document).on('show.bs.modal', '.modal', function () {
            $(this).appendTo('body');
        });
    }

    getCurrentUser() {
        this.currentUserAdminGQL.watch().valueChanges.subscribe(({data}) => {
            const a: any = data;
            this.userService.updateUser(a.currentUserAdmin);
        }, () => {
            this.userService.isAuthenticated = false;
            this.router.navigate(['/login']);
        });
    }

}
