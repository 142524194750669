<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <h1 class="page-title">Gestão <span>| Programa</span></h1>
            <a class="btn btn-gray-fill d-none">
                <svg-icon class="right mr-2" src="assets/svg/upload.svg"></svg-icon>
                Importar Base
            </a>
            <a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>
            <a href="javascript:void('');" class="btn btn-info text-white" (click)="getExcel()">
                <svg-icon class="svg-white right mr-2" src="assets/svg/excel.svg"></svg-icon>
                Exportar Excel
            </a>
        </div>

        <div class="row">
            <div class="col-xl-3 col-md-6 mb-4 mb-lg-0">
                <div class="card bg-light-success">
                    <div class="card-body widget-user py-2">
                        <div class="text-center">
                            <h2 class="font-weight-bold" data-plugin="counterup">{{modelDash?.finished}}</h2>
                            <h5>Finalizada</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-md-6 mb-4 mb-lg-0">
                <div class="card bg-light-info">
                    <div class="card-body widget-user py-2">
                        <div class="text-center">
                            <h2 class="font-weight-bold" data-plugin="counterup">{{modelDash?.received}}</h2>
                            <h5>Em Análise</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-md-6 mb-4 mb-lg-0">
                <div class="card bg-light-warning">
                    <div class="card-body widget-user py-2">
                        <div class="text-center">
                            <h2 class="font-weight-bold" data-plugin="counterup">{{modelDash?.pending}}</h2>
                            <h5>Pendente</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-md-6">
                <div class="card bg-light-danger">
                    <div class="card-body widget-user py-2">
                        <div class="text-center">
                            <h2 class="font-weight-bold" data-plugin="counterup">{{modelDash?.retrieved}}</h2>
                            <h5>Devolvida</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-block my-5">
            <hr/>
        </div>

        <div class="page-filter">
            <div class="input-material">
                <input id="name" [(ngModel)]="searchStandArtInput.keyword" class="form-control" type="text"
                       (keyup.enter)="searchStandArtInput.page = 1; actionSearch()"
                       [mask]="'00.000.000/0000-00'"
                       [dropSpecialCharacters]="false"
                       required/>
                <label for="name">
                    <svg-icon src="assets/svg/search.svg"></svg-icon>
                    <span>CNPJ</span>
                </label>
            </div>
            <div class="input-material select">
                <select id="gme" [(ngModel)]="searchStandArtInput.gme" class="form-control" required
                        [disabled]="user.profile !== 'admin'">
                    <option [ngValue]="undefined" selected>Todos</option>
                    <option *ngFor="let gme of listGme">{{gme}}</option>
                </select>
                <label for="gme" [ngClass]="{'disabled': (user.profile !== 'admin')}">GMR</label>
            </div>
            <div class="input-material">
                <input id="filterGVE" [(ngModel)]="searchStandArtInput.gve" class="form-control" type="text"
                       [readOnly]="user.profile !== 'admin'"
                       required/>
                <label for="filterGVE" [ngClass]="{'disabled': (user.profile !== 'admin')}">
                    <span>GVR</span>
                </label>
            </div>
            <div class="input-material select">
                <select id="type" [(ngModel)]="searchStandArtInput.userType" class="form-control" required>
                    <option [ngValue]="undefined" selected>Todos</option>
                    <option class="text-uppercase" *ngFor="let t of listType">{{t}}</option>
                </select>
                <label for="type">Tipo</label>
            </div>
            <div class="input-material select">
                <select id="ativoFilter" class="form-control" required [(ngModel)]="searchStandArtInput.type">
                    <option [ngValue]="undefined" selected>Todos</option>
                    <option [ngValue]="'adhesion'">Adesão</option>
                    <option [ngValue]="'standardization'">Padronização</option>
                </select>
                <label for="ativoFilter">Auditoria</label>
            </div>
            <div class="input-material select">
                <select id="status" [(ngModel)]="searchStandArtInput.status" class="form-control text-capitalize"
                        required>
                    <option [ngValue]="undefined" selected>Todos</option>
                    <option *ngFor="let l of listStatus" [value]="l">{{getTranslate(l)}}</option>
                </select>
                <label for="status">Status</label>
            </div>
            <div class="input-material">
                <input id="date" class="form-control picker-period" type="text"
                       required/>
                <label for="date">
                    <svg-icon src="assets/svg/date.svg" class="right"></svg-icon>
                    <span>Período</span>
                </label>
            </div>
            <div class="flex-grow-1 d-flex">
                <a (click)="searchStandArtInput.page = 1; actionSearch()"
                   class="btn btn-primary btn-lg text-white mr-1">Buscar</a>
                <a (click)="actionClearSearch()" class="btn btn-danger btn-lg ml-1"
                   *ngIf="!isNullOrUndefined(getFilterLocal('filterParticipants'))">
                    <svg-icon class="svg-white" src="assets/svg/trash.svg"></svg-icon>
                </a>
            </div>
        </div>

        <div class="table-responsive">
            <table class="table table-striped table_regular">
                <thead>
                <tr>
                    <th>Cnpj</th>
                    <th>Auditoria</th>
                    <th>CCE</th>
                    <th>Tipo</th>
                    <th>GMR</th>
                    <th>GVR</th>
                    <th>Solicitante</th>
                    <th>Data Recebimento</th>
                    <th>Status</th>
                    <th>Fachada</th>
                    <th>Frota</th>
                    <th></th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="standArtPage?.standart?.length === 0">
                    <td colspan="8" class="py-5">
                        <p class="py-5 mt-5 text-center">Nenhum registro encontrado.</p>
                    </td>
                </tr>
                <tr class="is-cursor" *ngFor="let i of standArtPage?.standart |
                paginate: {itemsPerPage: standArtPage.PageSize,
                currentPage: standArtPage.currentPage,
                totalItems: standArtPage.totalCount }">
                    <td>
                        <div class="bar-status">
                            <div class="bar-status-item"></div>
                            {{i.createdBy.cnpj}}
                        </div>
                    </td>
                    <td class="text-capitalize">{{getTranslate(i.type)}}</td>
                    <td>{{i.createdBy.cceName}}</td>
                    <td class="text-uppercase">
                        {{getTranslate(i?.createdBy?.type)}}
                    </td>
                    <td>{{i?.createdBy?.gme}}</td>
                    <td>{{i?.createdBy?.gve}}</td>
                    <td>{{i?.createdBy?.name}}</td>
                    <td>{{i?.updatedAt |date:'dd/MM/yyyy'}}</td>
                    <td class="text-capitalize">
                        <span class="badge size-sm"
                              [ngClass]="{
                               'badge-warning' : i?.status === 'review',
                               'badge-info' : i?.status === 'received',
                               'badge-danger' : i?.status === 'reproved',
                               'badge-success' : i?.status === 'approved'}">{{getTranslate(i?.status)}}</span>

                    </td>
                    <td class="text-capitalize">
                        <span class="badge size-sm"
                              [ngClass]="{
                               'badge-warning' : i?.frontStatus === 'review' || i?.frontStatus === 'pending',
                               'badge-info' : i?.frontStatus === 'received',
                               'badge-danger' : i?.frontStatus === 'reproved',
                               'badge-success' : i?.frontStatus === 'approved'}">{{getTranslate(i?.frontStatus)}}</span>
                    </td>
                    <td class="text-capitalize">
                        <span class="badge size-sm"
                              [ngClass]="{
                               'badge-warning' : i?.fleetStatus === 'review' || i?.fleetStatus === 'pending',
                               'badge-info' : i?.fleetStatus === 'received',
                               'badge-danger' : i?.fleetStatus === 'reproved',
                               'badge-success' : i?.fleetStatus === 'approved'}">{{getTranslate(i?.fleetStatus)}}</span>
                    </td>
                    <td>
                        <svg-icon [routerLink]="['/consultant/'+ i.id]" class="svg-primary" *ngIf="user.profile !== 'admin'"
                                  src="assets/svg/eye.svg"></svg-icon>
                        <svg-icon [routerLink]="['/measures/'+ i.id]" class="svg-primary" *ngIf="user.profile === 'admin'"
                                  src="assets/svg/edit.svg"></svg-icon>
                    </td>
                    <td>
                        <svg-icon (click)="openPdf(i.createdBy.id)" class="svg-primary"
                                  src="assets/svg/pdf.svg"></svg-icon>
                    </td>

                </tr>
                </tbody>
            </table>
        </div>
        <div class="text-right">
            <pagination-controls
                    [previousLabel]="labels.previousLabel"
                    [nextLabel]="labels.nextLabel"
                    [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                    [screenReaderPageLabel]="labels.screenReaderPageLabel"
                    [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
        </div>
    </div>
</div>
